// import { styled } from "styled-components";
import { styled as MuiStyled } from "@mui/material/styles";
import { Box, Button, Checkbox, Grid, TextField, Typography } from "@mui/material";

export const ModalTitle = MuiStyled((props) => <Typography {...props} />)({
  font: "bold 28px NotoSansKR-Medium",
});

export const ModalContent = MuiStyled((props) => <Typography {...props} />)({
  font: "600 18px Pretendard",
  fontSize: "clamp(12.7px, 4.05vw, 18px)",
});

export const ModalContentBox = MuiStyled((props) => <Box {...props} />)({
  marginTop: "20px",
  display: "flex",
  flexDirection: "column",
  gap: "5px",
});

export const ModalInput = MuiStyled((props) => <TextField size="small" fullWidth {...props} />)({});

export const ModalCheckbox = MuiStyled((props) => <Checkbox size="small" {...props} />)({});

export const ModalCheckboxLabel = MuiStyled((props) => <Typography {...props} />)({
  transform: "translate(-9px, 2px)",
  font: "11px Pretendard",
  fontSize: "clamp(11px, 3.49vw, 15px)",
});

export const ModalPlanBox = MuiStyled((props) => <Box {...props} />)({
  border: "1px solid #aaa",
  borderRadius: "4px",
  padding: "5px",
  width: "100%",
});

export const ModalPlanTitle = MuiStyled((props) => <Typography {...props} />)({
  font: "14px NotoSansKR-Medium",
  borderBottom: "1px solid #ddd",
});

export const ModalPlanGridBox = MuiStyled((props) => <Grid container rowGap={1} {...props} />)({
  padding: "5px 0 ",
  boxSizing: "border-box",
  width: "100%",
  overflow: "auto",
  maxHeight: "150px",
});

export const ModalPlanGridItem = MuiStyled((props) => <Grid item xs={6} {...props} />)({});

export const ModalPlanGridItemBox = MuiStyled((props) => <Box {...props} />)({
  display: "flex",
  alignItems: "center",
  gap: "5px",
  font: "14px NotoSansKR-Medium",
  margin: "0 2.5px",
  borderRadius: "10px",
  border: "1px solid #aaaaaa80",
  cursor: "pointer",
});

export const ModalButtonBox = MuiStyled((props) => <Box {...props} />)({
  marginTop: "20px",
  display: "flex",
  justifyContent: "center",
  gap: "30px",
});

export const ModalButton = MuiStyled((props) => (
  <Button
    variant="contained"
    sx={{
      font: "bold 18px NotoSansKR",
      p: "10px",
      bgcolor: props.type === "cancel" ? "#888" : "",
      "&:hover": { bgcolor: props.type === "cancel" ? "#777" : "" },
    }}
    {...props}
  />
))({});
