import React, {useState, useEffect} from 'react';

import BasicModal from './Modal';

import {Box, Typography, Card, CardActionArea } from '@mui/material'

const SubsItem = (props) => {
    const {item, title} = props
    const [open, setOpen] = useState(false);
    const remainDay = (new Date(item.expireDate) - new Date())/(1000*60*60*24);
    const [cardWarning, setCardWarning] = useState(false);
    const [logo, setLogo] = useState(null);

    useEffect(() => {
        if(title === "구독중" && remainDay < 7){
            setCardWarning(true)
        } 
        // eslint-disable-next-line
    }, []);
    useEffect(() => {  
        try{
            setLogo(require(`../assets/images/${item.game.name}.png`))
        }catch{
            setLogo(require(`../assets/images/withplus Logo.png`))
        }
        
    }, [item]);
    const handleClose = () => {

        setOpen(!open)
    }
    return (
        <>
            <Card sx={{borderRadius: '10px', mx:1, mb:1, px:1, backgroundColor:(cardWarning?"#cc94be":"#ffffff")}}>
                <CardActionArea onClick={handleClose}>
                    <Box sx={{width:'100%', display:'flex', justifyContent: 'center', pb:2}}>
                        <img src={ logo } width='80%' height='80%' alt={"withplus"} loading="lazy"/>
                    </Box>
                    
                    <Typography sx={{width:'100%', display:'flex', justifyContent: 'center', pb:2}}>{item?.game.name}</Typography>
                    <Box sx={{display:"flex", pb:1}}>
                        <Typography variant="body1" sx={{width:'100%'}}>만료날짜 : </Typography>
                        <Typography variant="body1" align="right" sx={{width:'100%'}}>{item?.expireDate.slice(0,10)} </Typography>
                    </Box>
                    <Box sx={{display:"flex", pb:1}}>
                        <Typography variant="body1" sx={{width:'100%'}}>접속 가능 인원 : </Typography>
                        <Typography variant="body1" align="right" sx={{width:'100%'}}>{item?.people} (명)</Typography>
                    </Box>
                </CardActionArea>
            </Card>
            <BasicModal open={open} handleClose={handleClose} item={item} title={title}/>
        </>
        
    )
}

export default SubsItem;