import { React, useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import PullToRefresh from "react-simple-pull-to-refresh";

import Header from "./Header";
import SideBar from "./SideBar";
import { socket } from "../utils/socket";

import { Box } from "@mui/system";

const Layout = (props) => {
  const { children } = props;
  const [cookies] = useCookies();
  const [innerWidth, setinnerWidth] = useState(window.innerWidth);
  const [lgup, setlgup] = useState(window.innerWidth > 900 ? true : false);
  const [open, setopen] = useState(false);
  const [name, setName] = useState("");
  const [status, setStatus] = useState("");

  const handleSideBar = () => {
    setopen(!open);
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  useEffect(() => {
    if (innerWidth < 900) setlgup(false);
    else setlgup(true);
  }, [innerWidth]);
  useEffect(() => {
    socket.emit("userInfo", { auth: cookies.auth });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  window.onresize = () => {
    setinnerWidth(window.innerWidth);
  };

  socket.on("userInfo", (data) => {
    setName(data.name);
    setStatus(data.status);
  });
  return (
    <Box sx={{ display: "flex", height: "100vh", backgroundColor: "#f5f5f5", overflow: "auto" }}>
      <SideBar drawer={lgup ? false : true} open={open} handleSideBar={handleSideBar} name={name} status={status} />

      <PullToRefresh onRefresh={handleRefresh} resistance={3} isPullable={!lgup}>
        {/* <Box sx={lgup ? { width: "100%", ml: "250px", height: "100vh", bgcolor: "red" } : { width: "100%" }}> */}
        <Box
          sx={{
            width: "100%",
            pl: lgup ? "250px" : "",
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            boxSizing: "border-box",
          }}
        >
          <Header menu={lgup ? false : true} handleSideBar={handleSideBar} name={name} status={status} />
          <Box sx={{ flex: 1, height: "100%", overflow: "auto" }}>{children}</Box>
        </Box>
      </PullToRefresh>
    </Box>
  );
};

export default Layout;
