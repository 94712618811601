import React from 'react';
import {
    Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
}from '@mui/material'

const ConfirmDialog = (props) => {
    const {open, handleClose, title, content} = props

    return (
        <div>
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
            {title}
            </DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {content}
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleClose} value={true}>예</Button>
            <Button onClick={handleClose} value={false}>아니오</Button>
            </DialogActions>
        </Dialog>
        </div>
    );
}

export default ConfirmDialog;