import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { socket } from "./../utils/socket";
import { ModifyModal } from "../components/Modal";

import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  Avatar,
  IconButton,
  Tooltip,
  Box,
  Typography,
  Divider,
} from "@mui/material";

import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const CustomerTable = (props) => {
  const navigate = useNavigate();
  const { rows } = props;
  const goDetailPage = (id) => {
    // alert("준비중입니다.");
    // return;
    navigate(`/customer/${id}`, { state: { test: "test" } });
  };
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow sx={{ backgroundColor: "rgb(240, 241, 243)" }}>
            <TableCell>이름</TableCell>
            <TableCell align="right">이메일</TableCell>
            <TableCell align="right">회사</TableCell>
            <TableCell align="right">연락처</TableCell>
            <TableCell align="right">구독 정보</TableCell>
            <TableCell align="right">가입일자</TableCell>
            <TableCell align="right">자세히</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, idx) => (
            <TableRow key={idx} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell component="th" scope="row">
                <Grid container sx={{ display: "flex", alignItems: "center" }} spacing={2}>
                  <Grid item>
                    <Avatar />
                  </Grid>
                  <Grid item>{row.name || "-"}</Grid>
                </Grid>
              </TableCell>
              <TableCell align="right">{row.email || "-"}</TableCell>
              <TableCell align="right">{row.company || "-"}</TableCell>
              <TableCell align="right">{row.phone || "-"}</TableCell>
              <TableCell align="right">{row.subscription || "-"}</TableCell>
              <TableCell align="right">{row.createdAt || "-"}</TableCell>
              <TableCell align="right">
                <IconButton onClick={() => goDetailPage(row._id)}>
                  <ArrowForwardIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const SubscribeTable = (props) => {
  const navigate = useNavigate();
  const { items, games } = props;

  const [icon, setIcon] = useState(<ExpandLessIcon />);
  const [hiddenTable, setHiddenTable] = useState(false);
  const [modifyModalOpen, setModifyModalOpen] = useState(false);
  const [modifyExpireDate, setModifyExpireDate] = useState(0);
  const [modifyPeople, setModifyPeople] = useState(1);
  const [modifyId, setModifyId] = useState("");

  const handleIcon = (e) => {
    if (hiddenTable) {
      setIcon(<ExpandLessIcon />);
    } else {
      setIcon(<ExpandMoreIcon />);
    }
    setHiddenTable(!hiddenTable);
  };
  const deleteGame = (gameId, adminId) => {
    if (window.confirm("현재 구독중인 상품을 삭제하시겠습니까?")) socket.emit("deleteGame", { gameId: gameId, adminId: adminId });
  };
  const handleModify = (expireDate, people, id) => {
    setModifyModalOpen(!modifyModalOpen);
    setModifyExpireDate(expireDate);
    setModifyPeople(people);
    setModifyId(id);
  };

  useEffect(() => {
    socket.on("deleteGame", (data) => {
      if (data.success) {
        alert("삭제가 완료되어 구독 신청 데이터가 삭제됩니다.");
        navigate(0);
      }
    });
    return () => {
      socket.off("deleteGame");
    };
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <Box sx={{ px: "24px", py: "24px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography sx={{ fontSize: "1.0909rem" }}>구독 정보</Typography>
        <Tooltip title={hiddenTable ? "열기" : "접기"} arrow>
          <IconButton onClick={handleIcon}>{icon}</IconButton>
        </Tooltip>
      </Box>
      <Divider />
      <TableContainer hidden={hiddenTable}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow sx={{ backgroundColor: "rgb(240, 241, 243)" }}>
              <TableCell>게임 이름</TableCell>
              <TableCell align="right">만료 날짜</TableCell>
              <TableCell align="right">접속 가능 인원</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items?.map((item, idx) => (
              <TableRow key={idx} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell> {games?.find((game) => game._id === item.gameId)?.name}</TableCell>
                <TableCell align="right"> {item.expireDate.slice(0, 10)}</TableCell>
                <TableCell align="right"> {item.people} (명)</TableCell>
                <TableCell align="right">
                  <Tooltip title="수정" arrow>
                    <IconButton onClick={() => handleModify(item.expireDate, item.people, item._id)}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="삭제" arrow>
                    <IconButton onClick={() => deleteGame(item.gameId, item.adminId)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ModifyModal
        open={modifyModalOpen}
        onClose={handleModify}
        expireDate={modifyExpireDate}
        people={modifyPeople}
        subscribeId={modifyId}
      />
    </>
  );
};

export const SubscriptionTable = (props) => {
  const navigate = useNavigate();
  const { items, games } = props;
  const [icon, setIcon] = useState(<ExpandLessIcon />);
  const [hiddenTable, setHiddenTable] = useState(false);
  const handleIcon = (e) => {
    if (hiddenTable) {
      setIcon(<ExpandLessIcon />);
    } else {
      setIcon(<ExpandMoreIcon />);
    }
    setHiddenTable(!hiddenTable);
  };
  const acceptGame = (gameId, adminId, isAccepted) => {
    if (isAccepted) {
      if (window.confirm("연장 신청을 승인하시겠습니까?")) socket.emit("acceptExtend", { gameId: gameId, adminId: adminId });
    } else {
      if (window.confirm("구독 승인하시겠습니까?")) socket.emit("acceptGame", { gameId: gameId, adminId: adminId });
    }
  };
  const rejectGame = (gameId, adminId, isAccepted) => {
    if (isAccepted) {
      if (window.confirm("연장 신청을 거절하시겠습니까?")) socket.emit("rejectExtend", { gameId: gameId, adminId: adminId });
    } else {
      if (window.confirm("구독 권한을 거절하시겠습니까?")) socket.emit("rejectGame", { gameId: gameId, adminId: adminId });
    }
  };

  useEffect(() => {
    socket.on("acceptGame", (data) => {
      if (data.success) {
        alert("승인이 완료되었습니다.");
        navigate(0);
      }
    });
    socket.on("rejectGame", (data) => {
      if (data.success) {
        alert("거절이 완료되어 구독 신청 데이터가 삭제됩니다.");
        navigate(0);
      }
    });
    return () => {
      socket.off("acceptGame");
      socket.off("rejectGame");
    };
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <Box sx={{ px: "24px", py: "24px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography sx={{ fontSize: "1.0909rem" }}>구독 신청 리스트</Typography>
        <Tooltip title={hiddenTable ? "열기" : "접기"} arrow>
          <IconButton onClick={handleIcon}>{icon}</IconButton>
        </Tooltip>
      </Box>
      <Divider />
      <TableContainer hidden={hiddenTable}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow sx={{ backgroundColor: "rgb(240, 241, 243)" }}>
              <TableCell>게임 이름</TableCell>
              <TableCell align="right">만료 날짜</TableCell>
              <TableCell align="right">접속 가능 인원</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items?.map((item, idx) => (
              <TableRow key={idx} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell>
                  {" "}
                  {games?.find((game) => game._id === item.gameId)?.name} {item.isAccepted ? "(연장)" : ""}
                </TableCell>
                <TableCell align="right">
                  {" "}
                  {item.isAccepted ? item.extendData.expireDate.slice(0, 10) : item.expireDate.slice(0, 10)}
                </TableCell>
                <TableCell align="right"> {item.isAccepted ? item.extendData.people : item.people} (명)</TableCell>
                <TableCell align="right">
                  <Tooltip title="승인" arrow>
                    <IconButton onClick={() => acceptGame(item.gameId, item.adminId, item.isAccepted)}>
                      <CheckIcon sx={{ color: "#33ff33" }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="삭제" arrow>
                    <IconButton onClick={() => rejectGame(item.gameId, item.adminId, item.isAccepted)}>
                      <CloseIcon sx={{ color: "red" }} />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
