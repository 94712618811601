import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { FileUploader } from "react-drag-drop-files";
import { read, utils, writeFile } from "xlsx";
import { useMediaQuery } from "react-responsive";

import {
  Box,
  Button,
  Grid,
  Stepper,
  Typography,
  Step,
  StepLabel,
  Skeleton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Divider,
} from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import PaidIcon from "@mui/icons-material/Paid";

import Layout from "components/Layout";
import { socket } from "utils/socket";

import styles from "./style.module.css";

export default () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const steps = ["담당자에게 메일 회신", "계정 등록", "결제 요청", "결제 완료 및 계정 생성"];

  const [info, setInfo] = useState({});
  const [currentStep, setCurrentStep] = useState(0);

  const goBackToCustomers = () => {
    navigate("/group");
  };

  const endStep = () => {
    alert("그룹 신청이 완료되었습니다.");
    navigate("/group");
  };

  const handleStep = (e) => {
    const step = e.target.id === "next" ? currentStep + 1 : currentStep - 1;
    let isAllAccepted = info.userData?.users.every((user) => user.isAccepted === true);

    if (step === 4 && !isAllAccepted) return;

    let status;

    switch (step) {
      case 0:
        status = "승인 대기";
        break;
      case 1:
        status = "계정 등록";
        break;
      case 2:
        status = "결제 요청";
        break;
      case 3:
        status = "결제 완료";
        break;
      case 4:
        status = "계정 생성 완료";
        break;
      default:
        status = "승인 대기";
    }

    socket.emit("updateGroupStatus", { id, status });
    setCurrentStep(step);
  };

  useEffect(() => {
    axios.get("/api/v1/group", { params: { id } }).then((res) => {
      let { data } = res.data;

      // setTimeout(() => {
      setInfo(data);

      switch (data.status) {
        case "승인 대기":
          setCurrentStep(0);
          break;
        case "계정 등록":
          setCurrentStep(1);
          break;
        case "결제 요청":
          setCurrentStep(2);
          break;
        case "결제 완료":
          setCurrentStep(3);
          break;
        case "계정 생성 완료":
          setCurrentStep(4);
          break;
        default:
          setCurrentStep(0);
      }
      // }, 1000);
    });
  }, []);

  useEffect(() => {
    socket.on("getAccountStatus", (data) => {
      // console.log(data);
      setInfo(data);
    });

    return () => {
      socket.off("getAccountStatus");
    };
  });

  return (
    <Layout>
      <Box className={styles.body}>
        <Box>
          <Button className={styles.back_btn} onClick={goBackToCustomers}>
            <KeyboardBackspaceIcon />
            <Typography>뒤로가기</Typography>
          </Button>
        </Box>

        <Grid container className={styles.grid}>
          <Grid item xs={12} sm={5} md={12} lg={4} className={styles.info}>
            <Box>
              <Box className={styles.header}>
                <Box className={styles.icon}>
                  <PersonOutlineOutlinedIcon />
                </Box>

                <Typography>기본 정보</Typography>
              </Box>

              <Box className={styles.content} data-label="담당자 이름">
                <Typography>{info.manager}</Typography>
              </Box>

              <Box className={styles.content} data-label="담당자 이메일">
                <Typography>{info.email}</Typography>
              </Box>

              <Box className={styles.content} data-label="담당자 연락처">
                <Typography>{info.phone}</Typography>
              </Box>

              <Box className={styles.content} data-label="신청 상품">
                {info.plan && <Typography>{info.plan + " " + info.month}</Typography>}
              </Box>

              <Box className={styles.content} data-label="신청 계정 수">
                <Typography>{info.account && info.account + " 계정"}</Typography>
              </Box>

              <Box className={styles.content} data-label="단체명">
                <Typography>{info.groupName}</Typography>
              </Box>

              <Box className={styles.content} data-label="단체 주소">
                <Typography>{info.groupAddress}</Typography>
              </Box>

              <Box className={styles.content} data-label="행정 서류 요청 (필요 시 입력)">
                <Typography>{info.postScript}</Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={7} md={12} lg={8}>
            <Box className={styles.payment}>
              <Box className={styles.header}>
                <Box className={styles.icon}>
                  <PaidIcon sx={{ color: "skyblue" }} />
                </Box>

                <Typography>단체 계정 신청 절차</Typography>
              </Box>

              <Box>
                <Stepper activeStep={currentStep} alternativeLabel>
                  {steps.map((label, index) => (
                    <Step key={label}>
                      <StepLabel
                        componentsProps={{
                          label: { style: { marginTop: "5px", font: "600 14px Pretendard" } },
                        }}
                      >
                        {label}
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>

                <Box sx={{ flex: 1, py: "10px", boxSizing: "border-box" }}>
                  {!info.manager && <Skeleton variant="ractangular" height={"100%"} />}

                  {info.manager && (
                    <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
                      {currentStep === 0 && <FirstStep />}
                      {currentStep === 1 && <SecondStep users={info.userData} />}
                      {currentStep === 2 && <ThirdStep info={info} />}
                      {currentStep === 3 && <FourthStep users={info.userData} info={info} />}
                      {currentStep === 4 && <FifthStep />}
                      <Box sx={{ display: "flex", justifyContent: "space-between", "> button": { font: "600 16px Pretendard" } }}>
                        <Button disabled={currentStep === 0} onClick={handleStep} id="back">
                          이전
                        </Button>

                        {currentStep === 4 ? (
                          <Button color="secondary" onClick={endStep}>
                            그룹 신청 완료 및 종료
                          </Button>
                        ) : (
                          <Button onClick={handleStep} id="next">
                            다음
                          </Button>
                        )}
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Layout>
  );
};

const Number = ({ number }) => {
  return (
    <Box
      sx={{
        font: "600 16px Pretendard",
        borderRadius: "50%",
        bgcolor: "#c33c3c",
        color: "#fff",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "30px",
        height: "30px",
        p: "2.5px",
        flexShrink: 0,
      }}
      children={number}
    />
  );
};

const FirstStep = () => {
  const downloadFile = () => {
    axios.get("/api/v1/download", { responseType: "blob" }).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const a = document.createElement("a");
      a.href = url;
      a.download = "위드플러스 라이브 단체계정 양식.xlsx";
      a.click();
      window.URL.revokeObjectURL(url);
    });
  };

  return (
    <Box sx={{ flex: 1, px: "10px", display: "flex", flexDirection: "column", gap: "10px" }}>
      <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
        <Number number={"1-1"} />

        <Box sx={{ display: "flex", gap: "5px" }}>
          <button onClick={downloadFile} style={{ width: "max-content", flexShrink: 0 }}>
            다운로드
          </button>
          <Typography sx={{ font: "700 16px Pretendard" }}>버튼을 클릭하여 해당 첨부파일과 함께 담당자에게 메일 회신</Typography>
        </Box>
      </Box>

      <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
        <Number number={"1-2"} />

        <Typography sx={{ font: "700 16px Pretendard" }}>
          담당자에게 첨부파일을 다운로드 받은 후, 해당 파일을 담당자에게 작성 및 회신 요청
        </Typography>
      </Box>
    </Box>
  );
};

const SecondStep = ({ users }) => {
  const { id } = useParams();
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const [file, setFile] = useState(null);
  const [userData, setUserData] = useState(null);

  const handleChange = (file) => {
    setFile(file);
  };

  const saveUsers = () => {
    socket.emit("saveUsers", { id, users: userData, fileName: file.name }, (res) => {
      if (res.success) {
        alert("계정 등록이 완료되었습니다.");
      }
    });
  };

  const deleteFile = () => {
    if (!window.confirm("파일을 삭제 및 재업로드하시겠습니까?")) return;
    setFile(null);
    socket.emit("deleteUsers", { id });
  };

  useEffect(() => {
    if (!file) {
      setUserData(null);
      return;
    }

    if (!file.size) {
      return;
    }

    (async () => {
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = read(data, { type: "array" });
        const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = utils.sheet_to_json(firstSheet, { header: 1 });
        let result = [];

        for (let i = 0; i < jsonData.length; i++) {
          if (jsonData[i].length === 0) continue;
          let data = jsonData[i].slice(1);

          if (typeof data[0] !== "number") continue;
          if (!data[1]) continue;

          result.push({
            id: data[0],
            name: data[1],
            email: data[2],
            company: data[3],
            phone: data[4],
          });
        }

        setUserData(result);
      };

      reader.readAsArrayBuffer(file);
    })();
  }, [file]);

  useEffect(() => {
    if (!users) return;

    setUserData(users.users);
    setFile({ name: users.fileName });
  }, [users]);

  return (
    <Box sx={{ flex: 1, px: "10px", display: "flex", flexDirection: "column", gap: "10px", boxSizing: "border-box" }}>
      <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
        <Number number={"2-1"} />

        <Typography sx={{ font: "700 16px Pretendard" }}>담당자가 회신한 첨부파일 업로드</Typography>
      </Box>

      {!file && (
        <FileUploader
          handleChange={handleChange}
          dropMessageStyle={{ backgroundColor: "#eee" }}
          hoverTitle=" "
          types={["xlsx"]}
          name="file"
          label="여기에 바로 파일을 업로드하거나 드롭하세요."
          classes={styles.file_uploader}
        />
      )}

      {file && (
        <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <img
            src={"https://noticon-static.tammolo.com/dgggcrkxq/image/upload/v1568016998/noticon/ztj0soogsjihpq87vfcp.png"}
            width={"30px"}
          />
          <Typography sx={{ font: "600 16px Pretendard" }}>{file.name}</Typography>
          <Button variant="contained" onClick={deleteFile} sx={{ font: "500 14px Pretendard", wordBreak: "keep-all" }}>
            삭제 및 재업로드
          </Button>
        </Box>
      )}

      <Box sx={{ display: "flex", gap: "10px", alignItems: "center", position: "relative" }}>
        <Number number={"2-2"} />

        <Typography sx={{ font: "700 16px Pretendard" }}>계정 등록 확인</Typography>

        {userData && (
          <Box sx={{ position: "absolute", right: 0 }}>
            <Button variant="contained" sx={{ font: "500 14px Pretendard", wordBreak: "keep-all" }} onClick={saveUsers}>
              계정 등록하기
            </Button>
          </Box>
        )}
      </Box>

      {userData && (
        <TableContainer className={styles.table_container} id={styles.group_list}>
          <Table>
            {!isMobile && (
              <TableHead>
                <TableRow>
                  <TableCell>이름</TableCell>
                  <TableCell>이메일</TableCell>
                  <TableCell>소속</TableCell>
                  <TableCell>연락처</TableCell>
                </TableRow>
              </TableHead>
            )}

            <TableBody>
              {userData &&
                userData.map((user, idx) => (
                  <TableRow key={idx}>
                    <TableCell data-label="이름">{user.name}</TableCell>
                    <TableCell data-label="이메일">{user.email}</TableCell>
                    <TableCell data-label="소속">{user.company}</TableCell>
                    <TableCell data-label="연락처">{user.phone || "-"}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

const ThirdStep = ({ info }) => {
  const [plan, setPlan] = useState("");
  const [month, setMonth] = useState("");
  const [account, setAccount] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  const [decoded, setDecoded] = useState(null);

  const handlePlan = (e) => {
    setPlan(e.target.value);
  };

  const handleMonth = (e) => {
    setMonth(e.target.value);
  };

  const handleAccount = (e) => {
    setAccount(e.target.value);
  };

  const removeURL = () => {
    socket.emit("deletePaymentURL", { id: info._id });
    setDecoded(null);
  };

  const requestPaymentURL = () => {
    socket.emit("requestPaymentURL", { id: info._id, plan, month, account, totalAmount }, (res) => {
      if (res.success) {
        alert("결제 URL이 생성되었습니다.");

        setDecoded(res.code);
      }
    });
  };

  useEffect(() => {
    if (!info) return;

    let month = ("00" + info.month.split("개월")[0]).slice(-2);

    switch (info.plan) {
      case "베이직 플랜":
        setPlan("basic");
        break;
      default:
        break;
    }

    setMonth(month * 1);
    setAccount(info.account * 1);
    setDecoded(info.paymentURL);
  }, [info]);

  useEffect(() => {
    if (!plan || !month || !account) return;

    if (month === 12) {
      if (account >= 50) {
        setTotalAmount(49500 * account);
      } else if (account >= 20) {
        setTotalAmount(69300 * account);
      } else if (account >= 10) {
        setTotalAmount(79200 * account);
      } else if (account >= 5) {
        setTotalAmount(89100 * account);
      } else {
        setTotalAmount(99000 * account);
      }
    } else {
      setTotalAmount(9900 * account * month);
    }
    // socket.emit("updateGroupInfo", { id: info.id, plan, month, account });
  }, [plan, month, account]);

  return (
    <Box sx={{ flex: 1, px: "10px", display: "flex", flexDirection: "column", gap: "10px" }}>
      <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
        <Number number={"3-1"} />

        <Typography sx={{ font: "700 16px Pretendard" }}>결제 URL 생성하기</Typography>
      </Box>

      <Grid container rowGap="20px">
        <Grid item xs={12} lg={4} sx={{ px: "10px" }}>
          <TextField
            disabled={decoded !== null}
            fullWidth
            label="상품명"
            value={plan}
            select
            required
            color="secondary"
            onChange={handlePlan}
          >
            <MenuItem value="basic">베이직 플랜</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={12} lg={4} sx={{ px: "10px" }}>
          <TextField
            disabled={decoded !== null}
            fullWidth
            label="신청 개월 수"
            value={month}
            onChange={handleMonth}
            select
            required
            color="secondary"
          >
            {new Array(11).fill(0).map((_, idx) => (
              <MenuItem key={idx} value={idx + 2}>
                {idx + 2}개월 {idx + 2 === 12 && "(1년, 할인 적용)"}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12} lg={4} sx={{ px: "10px" }}>
          <TextField
            disabled={decoded !== null}
            fullWidth
            label="신청 계정 수"
            value={account}
            onChange={handleAccount}
            select
            required
            color="secondary"
          >
            {new Array(46).fill(0).map((_, idx) => (
              <MenuItem key={idx} value={idx + 5}>
                {idx + 5}계정
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>

      <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
        <Number number={"3-2"} />

        <Typography sx={{ font: "700 16px Pretendard" }}>계정 등록 확인</Typography>
      </Box>

      <Box
        sx={{
          p: "20px",
          border: "1px solid #eee",
          boxShadow: "0 3px 6px #00000038",
          borderRadius: "10px",
          display: "flex",
          flexDirection: "column",
          gap: "15px",
        }}
      >
        <Typography sx={{ font: "600 18px Pretendard" }}>상품명: 베이직 플랜</Typography>

        <Typography sx={{ font: "600 18px Pretendard" }}>신청 개월 수: {month}개월</Typography>

        <Typography sx={{ font: "600 18px Pretendard" }}>신청 계정 수: {account}계정</Typography>

        <Typography color="primary" sx={{ font: "700 20px Pretendard", textAlign: "right" }}>
          총 결제 금액: {totalAmount.toLocaleString("ko-KR")}원
        </Typography>

        <Divider />

        <Button
          variant="contained"
          disabled={decoded}
          color="primary"
          sx={{ font: "600 16px Pretendard", p: "10px" }}
          onClick={requestPaymentURL}
        >
          {decoded ? "결제 URL 생성완료" : "결제 URL 생성하기"}
        </Button>
      </Box>

      <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
        <Number number={"3-3"} />

        <Typography sx={{ font: "700 16px Pretendard" }}>담당자에게 결제 요청 메일 회신</Typography>
      </Box>

      {decoded && (
        <Box sx={{ display: "flex", gap: "5px", alignItems: "center", width: "100%", pl: "10px" }}>
          <span style={{ flexShrink: 0, font: "600 16px Pretendard" }}>결제 URL : </span>
          <Typography
            sx={{
              font: "500 16px Pretendard",
              // width: "85%",
              flex: 1,
              textDecoration: "underline",
              "&:hover": { cursor: "pointer", color: "blue" },
            }}
            onClick={() => {
              navigator.clipboard.writeText("https://www.withplus.live/redirect?code=" + decoded);
              alert("URL이 복사되었습니다.");
            }}
            noWrap
          >
            {"https://www.withplus.live/redirect?code=" + decoded}
          </Typography>

          <Button
            variant="contained"
            sx={{ font: "600 16px Pretendard", p: "10px", width: "max-content" }}
            onClick={() => {
              navigator.clipboard.writeText("https://www.withplus.live/redirect?code=" + decoded);
              alert("URL이 복사되었습니다.");
            }}
          >
            복사
          </Button>

          <Button
            color="warning"
            variant="contained"
            sx={{ font: "600 16px Pretendard", p: "10px", width: "max-content" }}
            onClick={removeURL}
          >
            다시 url 생성하기
          </Button>
          {/* <img
            src={"https://noticon-static.tammolo.com/dgggcrkxq/image/upload/v1568016998/noticon/ztj0soogsjihpq87vfcp.png"}
            width={"30px"}
          />
          <Typography sx={{ font: "600 16px Pretendard" }}>{decoded}</Typography> */}
        </Box>
      )}
    </Box>
  );
};

const FourthStep = ({ users, info }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const [userData, setUserData] = useState(null);
  const [plan, setPlan] = useState("");
  const [month, setMonth] = useState("");
  const [account, setAccount] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  const [paymentStatus, setPaymentStatus] = useState(false);
  // const [decoded, setDecoded] = useState(null);

  const addAccount = () => {
    if (!window.confirm("계정 생성을 시작하시겠습니까?")) return;

    socket.emit("addAccount", { id: info._id, plan, month }, (res) => {
      if (res.success) {
        alert("계정 생성이 완료되었습니다.");
        // console.log("계정 생성이 완료되었습니다.");
      }
    });
  };

  useEffect(() => {
    if (!users) return;

    setUserData(users.users);
  }, [users]);

  useEffect(() => {
    socket.emit("paymentStatus", { id: info._id }, (res) => {
      const order = res.order[0];

      if (order.approvedAt) {
        setPaymentStatus(true);
      }
    });
  }, []);

  useEffect(() => {
    if (!info) return;

    let month = ("00" + info.month.split("개월")[0]).slice(-2);

    switch (info.plan) {
      case "베이직 플랜":
        setPlan("basic");
        break;
      default:
        break;
    }

    setMonth(month * 1);
    setAccount(info.account * 1);
  }, [info]);

  useEffect(() => {
    if (!plan || !month || !account) return;

    if (month === 12) {
      if (account >= 50) {
        setTotalAmount(49500 * account);
      } else if (account >= 20) {
        setTotalAmount(69300 * account);
      } else if (account >= 10) {
        setTotalAmount(79200 * account);
      } else if (account >= 5) {
        setTotalAmount(89100 * account);
      } else {
        setTotalAmount(99000 * account);
      }
    } else {
      setTotalAmount(9900 * account * month);
    }
    // socket.emit("updateGroupInfo", { id: info.id, plan, month, account });
  }, [plan, month, account]);

  return (
    <Box sx={{ flex: 1, px: "10px", display: "flex", flexDirection: "column", gap: "10px" }}>
      <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
        <Number number={"4-1"} />

        <Typography sx={{ font: "700 16px Pretendard" }}>결제 상황</Typography>
      </Box>

      <Box
        sx={{
          p: "20px",
          border: "1px solid #eee",
          boxShadow: "0 3px 6px #00000038",
          borderRadius: "10px",
          display: "flex",
          flexDirection: "column",
          gap: "15px",
        }}
      >
        <Typography sx={{ font: "600 18px Pretendard" }}>상품명: 베이직 플랜</Typography>

        <Typography sx={{ font: "600 18px Pretendard" }}>신청 개월 수: {month}개월</Typography>

        <Typography sx={{ font: "600 18px Pretendard" }}>신청 계정 수: {account}계정</Typography>

        <Typography color="primary" sx={{ font: "700 20px Pretendard", textAlign: "right" }}>
          총 결제 금액: {totalAmount.toLocaleString("ko-KR")}원
        </Typography>

        <Divider />

        <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <Typography sx={{ font: "600 18px Pretendard" }}>결제 상태: </Typography>
          <Typography sx={{ font: "600 22px Pretendard", color: paymentStatus ? "green" : "777" }}>
            {paymentStatus ? "결제 완료" : "결제 대기 중"}
          </Typography>
        </Box>
      </Box>

      <Box sx={{ display: "flex", gap: "10px", alignItems: "center", position: "relative", mt: "10px" }}>
        <Number number={"4-2"} />

        <Typography sx={{ font: "700 16px Pretendard" }}>계정 생성</Typography>

        {paymentStatus && (
          <Button
            variant="contained"
            color="secondary"
            sx={{ font: "600 16px Pretendard", position: "absolute", top: 0, right: 0 }}
            onClick={addAccount}
          >
            위드플러스 라이브에 {isMobile && <br />} 계정 생성 시작
          </Button>
        )}
      </Box>

      {userData && paymentStatus && (
        <TableContainer className={styles.table_container} id={styles.group_list} sx={{ mt: { xs: "20px", md: 0 } }}>
          <Table>
            {!isMobile && (
              <TableHead>
                <TableRow>
                  <TableCell>이름</TableCell>
                  <TableCell>이메일</TableCell>
                  <TableCell>소속</TableCell>
                  <TableCell>연락처</TableCell>
                  <TableCell>계정 상태</TableCell>
                </TableRow>
              </TableHead>
            )}

            <TableBody>
              {userData &&
                userData.map((user, idx) => (
                  <TableRow key={idx}>
                    <TableCell data-label="이름">{user.name}</TableCell>
                    <TableCell data-label="이메일">{user.email}</TableCell>
                    <TableCell data-label="소속">{user.company}</TableCell>
                    <TableCell data-label="연락처">{user.phone || "-"}</TableCell>
                    <TableCell data-label="계정 상태">
                      {(() => {
                        switch (user.isAccepted) {
                          case true:
                            return <Typography sx={{ color: "green", fontWeight: 600 }}>계정 생성 완료</Typography>;
                          case false:
                            return <Typography sx={{ color: "red", fontWeight: 600 }}>계정 생성 실패</Typography>;
                          default:
                            return "-";
                        }
                      })()}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

const FifthStep = () => {
  return (
    <Box sx={{ flex: 1, px: "10px", display: "flex", flexDirection: "column", gap: "10px" }}>
      <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
        <Number number={"5-1"} />

        <Typography sx={{ font: "700 16px Pretendard" }}>계정 생성 완료</Typography>
      </Box>
    </Box>
  );
};
