// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken } from "firebase/messaging";
import { socket } from "./socket";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBDKTZiCdWYrRvqBf-emUYjwc33KjZg6x8",
  authDomain: "withplus-solution.firebaseapp.com",
  projectId: "withplus-solution",
  storageBucket: "withplus-solution.appspot.com",
  messagingSenderId: "216301571755",
  appId: "1:216301571755:web:671bd8c39839eb77c671ee",
  measurementId: "G-VPBHM845X2",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const messaging = getMessaging(app);

export const getTokenOfAgree = () => {
  getToken(messaging, {
    vapidKey: "BMJy6hNKKJwGzrenyxCyHNc4U_Ao57tPGY60_O_Db4ldmd1cuJd_Hq9-TIB6kg5gZfYoH7pAHWGjv4kKHAVp_5s",
  })
    .then((currentToken) => {
      if (currentToken) {
        // console.log(currentToken);
        // alert(currentToken);
        socket.emit("register-token", { token: currentToken });
        // Send the token to your server and update the UI if necessary
        // ...
      } else {
        // Show permission request UI
        console.log("No registration token available. Request permission to generate one.");
        // alert("No registration token available. Request permission to generate one.");
        // ...
      }
    })
    .catch((err) => {
      // alert("An error occurred while retrieving token. ", err);
      console.log("An error occurred while retrieving token. ", err);
    });
};

// console.log("hello");
