import React from 'react';
import logo from '../assets/images/wLogo.png'
import Link from '@mui/material/Link';
import { useNavigate } from 'react-router-dom';

const Logo = () => {
    const navigate = useNavigate()

    return(
        <Link onClick={() => navigate('/')} sx={{cursor:"pointer"}}>
            <img src={logo} width="20%" alt={"name"}/>
        </Link>
    )
}

export default Logo;