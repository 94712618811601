import React, {useEffect, useState} from "react";
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import Layout from './../components/Layout';
import Kanban from './../components/Kanban';
import { socket } from '../utils/socket';

import {
    Grid, Typography, Box
} from '@mui/material'


function SubscribePage(props) {

    const navigate = useNavigate()
    const [cookies] = useCookies();
    const [subscribeData, setSubscribeData] = useState({});

    useEffect(() => {
        socket.emit("getSubscribeData", {token:cookies.auth})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        socket.on("getSubscribeData", async (data) => {
            await setSubscribeData(data)
        })

        socket.on("deleteOwnGame", (data) => {
            if(data.success) alert("삭제가 완료되었습니다.")
            else alert("삭제에 실패하였습니다.")
            navigate(0);
        })

        socket.on("modifySubScribe", (data) => {
            if(data.success){
                alert("수정이 완료되었습니다.")
                navigate(0)
            }
        })

        socket.on("extendSubScribe", (data) => {
            if(data.success){
                alert("신청이 완료되었습니다.")
                navigate(0)
            }
        })

        return () => {
            socket.off("getSubscribeData")
            socket.off("deleteOwnGame")
            socket.off("modifySubScribe")
            socket.off("extendSubScribe")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const content = () => {
        return(
            <Box sx={{pl:4}}>
                <Typography variant="h5" sx={{pt:3}}>구독 리스트</Typography>
                <Box sx={{display:"flex", width:'100%', py:2}}>
                    <Grid container sx={{pl:2, pr:4}}>
                        <Kanban title="구독 승인 전" count={subscribeData.subscribe_before?.length} items={subscribeData.subscribe_before} socket={socket}/>
                        <Kanban title="구독중" count={subscribeData.subscribe_ing?.length} items={subscribeData.subscribe_ing} socket={socket}/>
                        <Kanban title="구독 만료" count={subscribeData.subscribe_expire?.length} items={subscribeData.subscribe_expire} socket={socket}/>
                    </Grid>
                </Box>
            </Box>
        )
    }

    return (
        <Layout children = {content()}/>
    );
}

export default SubscribePage;