import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { koKR } from "@mui/material/locale";

import LoginPage from "./views/LoginPage/LoginPage";
import RegisterPage from "./views/RegisterPage/RegisterPage";
import DashBoardPage from "./views/DashBoardPage/DashBoardPage";
import CustomoersPage from "./views/CustomoersPage";
import SubscribePage from "./views/SubscribePage";
import ApplyPage from "./views/ApplyPage";
import CustomerDetailsPage from "./views/CustomerDetailsPage";
import CustomerDetailPage from "./views/CustomerDetailPage";
import Auth from "./auth/auth";
import "./App.css";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { SnackBarProvider } from "components/SnackBar";
import AWSSetting from "views/AWS_SettingPage/AWS_Setting";
import CouponSettingPage from "views/WithplusLive/CouponSettingPage";
import CustomersPage from "views/CustomersPage";
import GroupAccountPage from "views/GroupAccountPage";
import GroupDetailPage from "views/GroupAccountPage/detailPage";
// import "utils/firebase"
// import "utils/firebase"
// test-6c4e2fd623
// cohAcgxWAgcqykUKkNMDAmEAFF;

const theme = createTheme({
  typography: {
    fontFamily: "Pretendard",
    button: {
      textTransform: "none",
    },
  },
  koKR,
});

function App(props) {
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  // useEffect(() => {
  //   socket.on("versionUpdate", (version) => {
  //     if (version !== localStorage.getItem("version")) {
  //       localStorage.setItem("version", version);
  //       window.location.reload();
  //     }
  //   });

  //   return () => {
  //     socket.off("versionUpdate");
  //   };
  // });
  // useEffect(() => {
  //   alert("visible");

  //   const handleVisibilityChange = () => {
  //     if (document.visibilityState === "visible") {
  //       socket.emit("getVersion", {}, (version) => {
  //         if (version !== localStorage.getItem("version")) {
  //           localStorage.setItem("version", version);
  //           window.location.reload();
  //         }
  //       });
  //       // if (localStorage.getItem("version") !== packageJson.version) {
  //       //   localStorage.setItem("version", packageJson.version);
  //       //   window.location.reload();
  //       // }

  //       // Perform actions when the app becomes hidden
  //     }
  //   };

  //   document.addEventListener("visibilitychange", handleVisibilityChange);

  //   return () => {
  //     document.removeEventListener("visibilitychange", handleVisibilityChange);
  //   };
  // });
  // useEffect(() => {
  //   window.onload = function () {
  //     if (sessionStorage.getItem("onload")) return;

  //     sessionStorage.setItem("onload", true);
  //     console.log("onload");
  //   };
  // }, []);

  return (
    <ThemeProvider theme={theme}>
      <SnackBarProvider>
        <Router>
          <Routes>
            <Route exact path="/login" element={<Auth Component={LoginPage} isAuthRequired={false} />} />
            <Route exact path="/signup" element={<Auth Component={RegisterPage} isAuthRequired={false} />} />

            <Route exact path="/" element={<Auth Component={DashBoardPage} isAuthRequired={true} />} />
            <Route exact path="/dashboard" element={<Auth Component={DashBoardPage} isAuthRequired={true} />} />
            {isMobile ? (
              <Route exact path="/customers" element={<Auth Component={CustomersPage} isAuthRequired={true} />} />
            ) : (
              // <Route exact path="/customers" element={<Auth Component={CustomoersPage} isAuthRequired={true} />} />
              <Route exact path="/customers" element={<Auth Component={CustomersPage} isAuthRequired={true} />} />
            )}
            <Route exact path="/customer/:userId" element={<Auth Component={CustomerDetailPage} isAuthRequired={true} />} />

            <Route exact path="/subscribe" element={<Auth Component={SubscribePage} isAuthRequired={true} />} />
            <Route exact path="/apply" element={<Auth Component={ApplyPage} isAuthRequired={true} />} />
            <Route exact path="/customers/:userId" element={<Auth Component={CustomerDetailsPage} isAuthRequired={true} />} />
            {/* <Route exact path='/settings/aws' element={<AWSSetting/>}/> */}
            <Route exact path="/settings/aws" element={<Auth Component={AWSSetting} isAuthRequired={true} />} />
            <Route exact path="/withplus-live/coupon" element={<Auth Component={CouponSettingPage} isAuthRequired={true} />} />
            <Route exact path="/group" element={<Auth Component={GroupAccountPage} isAuthRequired={true} />} />
            <Route exact path="/group/:id" element={<Auth Component={GroupDetailPage} isAuthRequired={true} />} />
          </Routes>
        </Router>
      </SnackBarProvider>
    </ThemeProvider>
  );
}

export default App;
