import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import axios from "axios";

import { Box, Button, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

import styles from "./style.module.css";
import Layout from "components/Layout";

export default () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const [tableHeadStyle, setTableHeadStyle] = useState([]);
  const [rows, setRows] = useState([]);

  const goToDetail = (e) => {
    navigate(`/group/${e.currentTarget.id}`);
  };

  useEffect(() => {
    if (isMobile) {
      setTableHeadStyle([styles.hidden]);
    } else {
      setTableHeadStyle([]);
    }
  }, [isMobile]);

  useEffect(() => {
    axios.get("api/v1/group").then((res) => {
      setRows(res.data.data);
    });
  }, []);

  return (
    <Layout>
      <Box className={styles.body}>
        <Typography className={styles.title}>그룹 신청 관리</Typography>

        <Box>
          <TableContainer className={styles.table_container}>
            <Table>
              <TableHead className={tableHeadStyle.join(" ")}>
                <TableRow>
                  <TableCell>담당자</TableCell>
                  <TableCell>이메일</TableCell>
                  <TableCell>연락처</TableCell>
                  <TableCell>신청 플랜</TableCell>
                  <TableCell>신청 계정 수</TableCell>
                  <TableCell>신청 개월 수</TableCell>
                  <TableCell>신청 상태</TableCell>
                  <TableCell align="right">자세히</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row._id} sx={{ display: row.status === "계정 생성 완료" ? "none" : "" }}>
                    <TableCell data-label="담당자">{row.manager}</TableCell>
                    <TableCell data-label="이메일">{row.email}</TableCell>
                    <TableCell data-label="연락처">{row.phone}</TableCell>
                    <TableCell data-label="신청플랜">{row.plan}</TableCell>
                    <TableCell data-label="신청계정수">{row.account}</TableCell>
                    <TableCell data-label="신청개월수">{row.month}</TableCell>
                    <TableCell data-label="신청상태">{row.status}</TableCell>
                    {!isMobile && (
                      <TableCell align="right">
                        <IconButton size="small" onClick={goToDetail} id={row._id}>
                          <ArrowRightAltIcon />
                        </IconButton>
                      </TableCell>
                    )}
                    {isMobile && (
                      <TableCell>
                        <Button onClick={goToDetail} id={row._id}>
                          <ArrowRightAltIcon />
                          <Typography>자세히</Typography>
                        </Button>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Layout>
  );
};
