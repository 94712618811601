import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

import { Box, TextField, InputAdornment, IconButton, Typography, Button, Grid, Tabs, Tab } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Layout from "components/Layout";
import classes from "./style.module.css";
import { useNavigate } from "react-router-dom";
import { CustomerTable } from "components/Table";
import * as API from "utils/api";

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

export default () => {
  const [ref, inView] = useInView({
    threshold: 0.8,
  });

  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [done, setDone] = useState(false);
  const [tabValue, setTabValue] = useState("");
  const [totalCount, setTotalCount] = useState(0);

  const scrollToTop = () => {
    document.getElementById("body").scrollIntoView({ behavior: "smooth" });
  };

  const handleTab = (e, newValue) => {
    setTabValue(newValue);
    setName("");
    setUsers([]);
    setPage(1);
    setDone(false);
    setTotalCount(0);
    getUsers(1, newValue, "");
  };

  const handleSearch = (e) => {
    if (e.target[0]) {
      e.preventDefault();
      getUsers(1, tabValue, e.target[0].value);
    } else {
      getUsers(1, tabValue, name);
    }

    setUsers([]);
    setPage(1);
    setDone(false);
    setTotalCount(0);
  };

  const getUsers = (page, plan, name) => {
    // axios.get("/api/v1/users", { params: { page, plan, name } }).then((res) => {
    API.GET_USERS({ page, plan, name }).then((res) => {
      const { users: data, totalCount } = res.data;
      setTotalCount(totalCount);

      if (data.length === 0) {
        setDone(true);
        setLoading(false);
        return;
      }

      const newUsers = data.map((user) => {
        let subscription = "정보 없음";
        let plan = user.plan;

        if (plan === "free") {
          subscription = "무료 플랜";
        } else {
          switch (plan) {
            case "basic":
              subscription = "베이직";

              if (user.planType === "yearly") {
                subscription += " 구독 (연간 결제)";
              } else if (user.planType === "monthly") {
                subscription += " 구독 (월간 결제)";
              } else if (user.planType === "period") {
                subscription += " (기간 선택)";
              }
              break;

            case "basic_yearly":
              subscription = "베이직 구독 (연간 결제)";
              break;

            case "basic_monthly":
              subscription = "베이직 구독 (월간 결제)";
              break;
            default:
              break;
          }
        }

        return {
          _id: user._id,
          name: user.name,
          email: user.email,
          company: user.company,
          phone: user.phone,
          subscription,
          createdAt: user.createdAt,
        };
      });

      const newUserList = page === 1 ? newUsers : [...users, ...newUsers];

      setUsers(newUserList);
      setPage((prev) => prev + 1);
      setLoading(false);
    });
  };

  useEffect(() => {
    if (done) return;

    if (inView) {
      setLoading(true);
      if (page > 1) {
        sleep(1000).then(() => {
          getUsers(page, tabValue, name);
        });
      }
    } else {
      setLoading(false);
    }
  }, [inView]);

  useEffect(() => {
    sleep(1000).then(() => {
      getUsers(page, tabValue, name);
    });
  }, []);

  return (
    <Layout>
      <Box className={classes.body} id="body">
        <h1 className={classes.title}> 유저 관리 {`(${totalCount})`}</h1>

        <Box className={classes.tabs_container}>
          <Tabs value={tabValue} onChange={handleTab} variant="scrollable" scrollButtons allowScrollButtonsMobile>
            <Tab label="전체" value="" />
            <Tab label="무료 플랜" value="free" />
            <Tab label="베이직" value="basic" />
          </Tabs>
        </Box>

        <Box component="form" className={classes.search_box} onSubmit={handleSearch}>
          <TextField
            size="small"
            sx={{ flex: 1, bgcolor: "#fff" }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleSearch}>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            placeholder="이름을 입력하세요."
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Box>

        <Box className={classes.user_list}>
          {isMobile && users.map((user) => <Card key={user._id} {...user} />)}
          {/* {!isMobile && users.map((user) => <Card key={user._id} {...user} />)} */}
          {!isMobile && <CustomerTable rows={users} />}
          {/* {isMobile && <CustomerTable rows={users} />} */}
        </Box>

        {/* {isMobile && ( */}
        <Box ref={ref} className={classes.loading_box}>
          {loading && <CircularProgress />}
          {done && <Typography fontWeight={600}>더 표시할 유저가 없습니다.</Typography>}
        </Box>
        {/* )} */}
      </Box>

      <IconButton className={classes.slide_up_btn} onClick={scrollToTop}>
        <KeyboardArrowUpIcon />
      </IconButton>
    </Layout>
  );
};

const Card = (props) => {
  const { name, email, company, phone, subscription, _id, createdAt } = props;
  const navigate = useNavigate();

  const goToDetails = (e) => {
    // alert("준비중입니다.");
    // return;
    // navigate(`/customer/6372e9641cacb03139d0abbd`);
    navigate(`/customer/${e.currentTarget.id}`);
    // window.location.href = `https://local.cgcloud.kr/customers/6372e9641cacb03139d0abbd`;
  };

  return (
    <Box className={classes.card}>
      <Box className={classes.card_row}>
        <Typography noWrap>{name || "-"}</Typography>
      </Box>

      <Box className={classes.card_row}>
        <Grid container>
          <Grid item xs={4}>
            <Typography className={classes.type}>이메일</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography className={classes.value} noWrap>
              {email || "-"}
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box className={classes.card_row}>
        <Grid container>
          <Grid item xs={4}>
            <Typography className={classes.type}>회사</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography className={classes.value} noWrap>
              {company || "-"}
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box className={classes.card_row}>
        <Grid container>
          <Grid item xs={4}>
            <Typography className={classes.type}>연락처</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography className={classes.value} noWrap>
              {phone || "-"}
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box className={classes.card_row}>
        <Grid container>
          <Grid item xs={4}>
            <Typography className={classes.type}>구독 정보</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography className={classes.value} noWrap>
              {subscription || "-"}
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box className={classes.card_row}>
        <Grid container>
          <Grid item xs={4}>
            <Typography className={classes.type}>가입 일자</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography className={classes.value} noWrap>
              {createdAt || "-"}
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Button className={classes.detail_btn} variant="contained" fullWidth onClick={goToDetails} id={_id}>
        {"> 자세히 보기"}
      </Button>
    </Box>
  );
};
