import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Layout from "../components/Layout";
import { CustomerDetailTab } from "../components/Tab";
import { SubscribeTable, SubscriptionTable } from "../components/Table";
import { socket } from "../utils/socket";

import { Box, Grid, Link, Typography, Avatar, Button, Divider, Paper, Menu, MenuItem, ListItemIcon, ListItemText } from "@mui/material";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import WatchLaterIcon from "@mui/icons-material/WatchLater";

const CustomerDetailPage = (props) => {
  const navigate = useNavigate();
  const { userId } = useParams();
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);
  const [detailTabIndex, setDetailTabIndex] = useState(0);
  const [userInfo, setUserInfo] = useState({});
  const [subscribesBefore, setSubscribesBefore] = useState([]);
  const [subscribesIng, setSubscribesIng] = useState([]);
  const [games, setGames] = useState([]);

  const goBack = () => {
    navigate("/customers");
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleTabIndex = (e) => {
    setDetailTabIndex(e.currentTarget.id * 1);
  };
  const acceptUser = (e) => {
    if (window.confirm("회원가입 승인을 진행하시겠습니까?")) {
      socket.emit("acceptUser", { userId: userId });
    }
  };
  const rejectUser = () => {
    if (window.confirm("가입 승인을 거절하시겠습니까?")) {
      socket.emit("rejectUser", { userId: userId });
    }
  };

  useEffect(() => {
    socket.on("getUserInfo", (data) => {
      setUserInfo(data.info);
      setSubscribesBefore(data.subscribes_before);
      setSubscribesIng(data.subscribes_ing);
    });
    socket.on("getAllGame", (data) => {
      setGames(data.games);
    });
    socket.on("acceptUser", (data) => {
      if (data.success) {
        alert("승인되었습니다.");
        navigate(0);
      }
    });
    socket.on("acceptExtend", (data) => {
      if (data.success) {
        alert("승인되었습니다.");
        navigate(0);
      }
    });
    socket.on("rejectUser", (data) => {
      if (data.success) {
        alert("거절이 완료되어 회원가입 데이터가 삭제됩니다.");
        navigate("/customers");
      }
    });
    socket.on("rejectExtend", (data) => {
      if (data.success) {
        alert("연장신청이 거절되었습니다.");
        navigate(0);
      }
    });
    socket.on("modifySubScribe", (data) => {
      if (data.success) {
        alert("수정이 완료되었습니다.");
        navigate(0);
      }
    });
    socket.emit("getUserInfo", { id: userId });
    socket.emit("getUserSubscribeData");

    return () => {
      socket.off("getUserInfo");
      socket.off("getAllGame");
      socket.off("acceptUser");
      socket.off("rejectUser");
      socket.off("modifySubScribe");
      socket.off("acceptExtend");
      socket.off("rejectExtend");
    };
    // eslint-disable-next-line
  }, []);
  const content = () => {
    return (
      <Box sx={{ py: "24px" }}>
        <Grid container sx={{ display: "flex", justifyContent: "center" }}>
          <Grid item xs={11} sm={11} md={9}>
            <Link component="button" underline="hover" sx={{ display: "flex", color: "black", mb: "32px" }} onClick={goBack}>
              <ArrowBackIcon />
              <Typography>Customers</Typography>
            </Link>
            <Box sx={{ display: "flex", justifyContent: "space-between", mb: "24px" }}>
              <Grid container sx={{ display: "flex", alignItems: "center" }} spacing={2}>
                <Grid item>
                  <Avatar size="large" />
                </Grid>
                <Grid item>
                  <Typography variant="h4">{userInfo.name}</Typography>
                  <Box sx={{ display: "flex" }}>
                    <Typography variant="subtitle">Status:&nbsp;</Typography>
                    <Typography variant="subtitle" sx={{ backgroundColor: "rgba(55, 65, 81, 0.2)", borderRadius: "16px", px: 1 }}>
                      {" "}
                      {userInfo.status}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Box hidden={userInfo.isAccepted}>
                <Button variant="contained" size="large" endIcon={<KeyboardArrowDownIcon />} children={"Actions"} onClick={handleMenu} />
                <Menu anchorEl={anchorEl} open={menuOpen} onClose={handleCloseMenu}>
                  <Box sx={{ width: 120 }}>
                    <MenuItem onClick={acceptUser}>
                      <ListItemIcon>
                        <CheckIcon sx={{ color: "#33ff33" }} />
                      </ListItemIcon>
                      <ListItemText>수락</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={rejectUser}>
                      <ListItemIcon>
                        <CloseIcon sx={{ color: "red" }} />
                      </ListItemIcon>
                      <ListItemText>거절</ListItemText>
                    </MenuItem>
                  </Box>
                </Menu>
              </Box>
            </Box>
            <CustomerDetailTab
              detailTabIndex={detailTabIndex}
              handleTabIndex={handleTabIndex}
              badgeCount={subscribesBefore.length}
              isAccepted={userInfo.isAccepted}
            />
            <Paper hidden={detailTabIndex !== 0}>
              <Box sx={{ px: "24px", py: "24px" }}>
                <Typography sx={{ fontSize: "1.0909rem" }}>Basic Detail</Typography>
              </Box>
              <Divider />
              <Box>
                <Grid container>
                  <Grid item xs={12} md={3} sx={{ p: "8px" }}>
                    <Typography variant="subtitle2">이름</Typography>
                  </Grid>
                  <Grid item xs={12} md={9} sx={{ p: "8px" }}>
                    <Typography variant="body2" sx={{ color: "gray" }}>
                      {userInfo.name}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Divider />
              <Box>
                <Grid container>
                  <Grid item xs={12} md={3} sx={{ p: "8px" }}>
                    <Typography variant="subtitle2">이메일</Typography>
                  </Grid>
                  <Grid item xs={12} md={9} sx={{ p: "8px" }}>
                    <Typography variant="body2" sx={{ color: "gray" }}>
                      {userInfo.email}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Divider />
              <Box>
                <Grid container>
                  <Grid item xs={12} md={3} sx={{ p: "8px" }}>
                    <Typography variant="subtitle2">Status</Typography>
                  </Grid>
                  <Grid item xs={12} md={9} sx={{ p: "8px" }}>
                    <Typography variant="body2" sx={{ color: "gray" }}>
                      {userInfo.status}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Divider />
              <Box>
                <Grid container>
                  <Grid item xs={12} md={3} sx={{ p: "8px" }}>
                    <Typography variant="subtitle2">승인 여부</Typography>
                  </Grid>
                  <Grid item xs={12} md={9} sx={{ p: "8px" }}>
                    {userInfo.isAccepted ? <CheckIcon sx={{ color: "#33ff33" }} /> : <CloseIcon sx={{ color: "red" }} />}
                  </Grid>
                </Grid>
              </Box>
              <Divider />
            </Paper>
            <Paper hidden={detailTabIndex !== 1} sx={{ my: 1 }}>
              <SubscribeTable items={subscribesIng} games={games} />
            </Paper>
            <Paper hidden={detailTabIndex !== 1} sx={{ my: 1 }}>
              <SubscriptionTable items={subscribesBefore} games={games} />
            </Paper>
            <Paper hidden={detailTabIndex !== 2}>
              <Box sx={{ px: "24px", py: "24px" }}>
                <Typography sx={{ fontSize: "1.0909rem" }}>Logs</Typography>
              </Box>
              <Divider />
              <Box sx={{ px: "24px", py: "10px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <WatchLaterIcon fontSize="large" />
                <Typography>시스템 준비중...</Typography>
                {/* <Grid container sx={{py:1}}>
                                    <Grid item xs={1} sx={{display:"flex", justifyContent:"center", borderRadius:"16px", backgroundColor:"rgb(121, 131, 145)"}}>
                                        <Typography sx={{fontSize:"1.0909rem"}}>
                                            11 / 20
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3} sx={{ml:2}}>
                                        <Typography>Governance 6</Typography>
                                    </Grid>
                                    <Grid item xs={6.5}>
                                        <Typography sx={{color:"rgb(160, 174, 192)"}}> 결제 금액 : \1,000,000</Typography>
                                    </Grid>
                                    <Grid item xs={1.2}>
                                        <Box sx={{display:"flex", justifyContent:"center", backgroundColor:"rgb(20, 184, 166)", borderRadius:"12px", height:"28px", alignItems:"center"}}>
                                            <Typography>결제 완료</Typography>
                                        </Box>
                                    </Grid>
                                </Grid> */}
              </Box>
              {/* <Divider/>
                            <Box sx={{px:"24px", py:"10px", display:"flex", justifyContent:"center", alignItems:"center"}}>
                                <Grid container sx={{py:1}}>
                                    <Grid item xs={1} sx={{display:"flex", justifyContent:"center", borderRadius:"16px", backgroundColor:"rgb(121, 131, 145)"}}>
                                        <Typography sx={{fontSize:"1.0909rem"}}>
                                            11 / 21
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3} sx={{ml:2}}>
                                        <Typography>Great Deal</Typography>
                                    </Grid>
                                    <Grid item xs={6.5}>
                                        <Typography sx={{color:"rgb(160, 174, 192)"}}> 결제 금액 : \1,300,000</Typography>
                                    </Grid>
                                    <Grid item xs={1.2}>
                                        <Box sx={{display:"flex", justifyContent:"center", backgroundColor:"rgb(33, 150, 243)", borderRadius:"12px", height:"28px", alignItems:"center"}}>
                                            <Typography>결제 대기중</Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box> */}
            </Paper>
          </Grid>
        </Grid>
      </Box>
    );
  };

  return <Layout children={content()} />;
};

export default CustomerDetailPage;
