import React, { useState, useEffect, useCallback } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { useWebAuthn } from "react-hook-webauthn";
import PullToRefresh from "react-simple-pull-to-refresh";
import { useMediaQuery } from "react-responsive";

import "./LoginPage.css";
import logo from "../../assets/images/withplus Logo.png";
import { socket } from "../../utils/socket";
import config from "config";

import {
  Button,
  Grid,
  TextField,
  FormHelperText,
  Container,
  Avatar,
  Typography,
  Box,
  Checkbox,
  Link,
  FormControlLabel,
} from "@mui/material";

import { deepPurple } from "@mui/material/colors";

import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

const rpOptions = {
  rpId: config.URL,
  rpName: "my super app",
};

const LoginPage = (props) => {
  const navigate = useNavigate();
  const date = new Date();
  const [, setCookie] = useCookies();
  const [loginType, setloginType] = useState("");
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [checkBox, setCheckBox] = useState([false, false]);
  const [helperStyle, setHelperStyle] = useState(false);
  const { getCredential, getAssertion } = useWebAuthn(rpOptions);

  const isMobile = useMediaQuery({ query: "(max-width: 900px)" });

  const handleRefresh = () => {
    setTimeout(() => {
      window.location.reload();
    }, 100);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (loginType === "") {
      setHelperStyle(true);
      return;
    }
    if (loginType !== "") {
      socket.emit("login", { loginType: loginType, email: email, password: password });
    }
  };
  const handleEmail = (e) => {
    setemail(e.target.value);
  };
  const handlePassword = (e) => {
    setpassword(e.target.value);
  };
  const handleChangeSuperAdmin = (e) => {
    if (!e.target.checked) {
      let newArr = [...checkBox];
      newArr[0] = false;
      setCheckBox(newArr);
    } else {
      setCheckBox([e.target.checked, !e.target.checked]);
    }
  };
  const handleChangeAdmin = (e) => {
    if (!e.target.checked) {
      let newArr = [...checkBox];
      newArr[1] = false;
      setCheckBox(newArr);
    } else {
      setCheckBox([!e.target.checked, e.target.checked]);
    }
  };

  const onRegister = useCallback(
    async (login) => {
      const credential = await getCredential({
        challenge: "stringFromServer",
        userDisplayName: login,
        userId: login,
        userName: login,
      });
      return credential;
    },
    [getCredential]
  );

  const onAuth = useCallback(async () => {
    const assertion = await getAssertion({ challenge: "stringFromServer" });
    return assertion;
  }, [getAssertion]);

  useEffect(() => {
    if (checkBox[0]) setloginType("SuperAdmin");
    else if (checkBox[1]) setloginType("Admin");
    else setloginType("");
  }, [checkBox]);

  useEffect(() => {
    // alert(localStorage.getItem("webAuthn"));
    if (localStorage.getItem("webAuthn")) {
      try {
        onAuth().then((assertion) => {
          let webAuthn = assertion.id;

          socket.emit("webAuthnLogin", { webAuthn: webAuthn }, (res) => {
            if (res.success) {
              let expires = new Date();
              expires.setTime(date.getTime() + 1000 * 60 * 60);
              setCookie("auth", res.auth, { path: "/", expires: expires });
              navigate("/");
            }
          });
        });
      } catch (e) {}
    }

    socket.on("login", async (data) => {
      if (data.success) {
        let expires = new Date();
        // expires.setTime(date.getTime() + 1000 * 20);
        // expires.setTime(date.getTime() + 1000 * 60);

        // expires.setTime(date.getTime() + 1000 * 60 * 60);
        // setCookie("auth", data.auth, { path: "/", expires: expires });
        if (!localStorage.getItem("webAuthn")) {
          try {
            const publicKey = await onRegister(data.name);
            const webAuthn = publicKey.id;

            socket.emit("saveWebAuthToken", { webAuthn: webAuthn, auth: data.auth });

            localStorage.setItem("webAuthn", webAuthn);

            expires.setTime(date.getTime() + 1000 * 60 * 60);
            setCookie("auth", data.auth, { path: "/", expires: expires });
            navigate("/");
          } catch (e) {
            expires.setTime(date.getTime() + 1000 * 60 * 60);
            setCookie("auth", data.auth, { path: "/", expires: expires });
            navigate("/");
          }
        } else {
          expires.setTime(date.getTime() + 1000 * 60 * 60);
          setCookie("auth", data.auth, { path: "/", expires: expires });
          navigate("/");
        }
        // navigate("/");
      } else {
        alert("로그인에 실패하였습니다.");
      }
    });

    return () => {
      socket.off("login");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PullToRefresh onRefresh={handleRefresh} isPullable={isMobile}>
      <Box sx={{ height: "100vh", overflow: "auto" }}>
        <Box sx={{ width: { xs: "50%", md: "400px" } }}>
          <img src={logo} alt={"withplus"} style={{ width: "100%" }} />
        </Box>
        <Container maxWidth="xs">
          <Box
            className="box"
            onSubmit={handleSubmit}
            component="form"
            sx={{ mt: 1, alignItems: "center", flexDirection: "column", display: "flex" }}
          >
            <Avatar sx={{ m: 1, bgcolor: deepPurple[500], width: 56, height: 56 }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5" sx={{ py: 1 }}>
              로그인
            </Typography>
            <Box sx={{ width: "100%", borderRadius: "10px", border: 1, borderColor: "rgba(0,0,0,0.2)" }}>
              <FormHelperText error={helperStyle} sx={{ textAlign: "center" }}>
                {"로그인 방식을 선택해주세요."}
              </FormHelperText>
              <Grid container sx={{ px: 1 }}>
                <Grid item xs={6} sx={{ px: 4 }}>
                  <FormControlLabel label="SuperAdmin" control={<Checkbox checked={checkBox[0]} onChange={handleChangeSuperAdmin} />} />
                </Grid>
                <Grid item xs={6} sx={{ px: 4 }}>
                  <FormControlLabel label="Admin" control={<Checkbox checked={checkBox[1]} onChange={handleChangeAdmin} />} />
                </Grid>
              </Grid>
            </Box>
            <TextField
              type="text"
              margin="normal"
              fullWidth
              label="이메일"
              required
              onChange={(e) => handleEmail(e)}
              inputProps={{ autoCapitalize: "none" }}
            ></TextField>
            <TextField type="password" margin="normal" fullWidth label="패스워드" required onChange={(e) => handlePassword(e)}></TextField>

            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 1 }}>
              로그인
            </Button>

            <Grid container>
              <Grid item xs>
                <Link className="test" href="#" onClick={() => alert("준비중입니다.")}>
                  비밀번호 찾기 및 변경
                </Link>
              </Grid>
              <Grid item>
                <Link className="test" href="/signup">
                  회원가입
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </PullToRefresh>
  );
};

export default LoginPage;
