import React, { useEffect, useState } from "react";

import Layout from "../../components/Layout";
import { getTokenOfAgree } from "utils/firebase";

import { Box, Grid, Typography } from "@mui/material";

import PeopleIcon from "@mui/icons-material/People";
import NorthIcon from "@mui/icons-material/North";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SouthIcon from "@mui/icons-material/South";
//
import styles from "./styles.module.css";
import * as API from "utils/api";

function DashBoardPage(props) {
  const [history, setHistory] = useState({});

  useEffect(() => {
    // console.log(Notification);
    const isSupported = () => "Notification" in window && "serviceWorker" in navigator && "PushManager" in window;

    // console.log(isSupported());
    // return;
    if (!isSupported()) return;
    if (Notification.permission === "granted") return;

    setTimeout(() => {
      Notification.requestPermission().then((permission) => {
        // console.log(permission);
        if (permission === "granted") {
          // console.log("Notification permission granted.");
          // alert("Notification permission granted.");
          getTokenOfAgree();
        } else {
          console.log("Unable to get permission to notify.");
        }
      });
    }, 1500);
  }, []);

  useEffect(() => {
    API.GET_LIVE_HISTORY().then((res) => {
      setHistory(res.data);
    });
  }, []);

  const Content = () => {
    return (
      <Box sx={{ p: "10px", boxSizing: "border-box" }}>
        <Typography sx={{ fontSize: "2rem", fontWeight: 700 }}>대시보드</Typography>

        <Grid container>
          <Grid className={styles["card-grid"]} item xs={12} sm={6} lg={4} xl={3}>
            <Box className={styles.card}>
              <Box>
                <Box>
                  <Typography className={styles.title} sx={{ color: "rgb(102, 112, 133)" }}>
                    총 유저 수
                  </Typography>

                  <Typography className={styles.value}> {(history.data?.totalUsers || 0) + " 명"}</Typography>
                </Box>
                <Box className={styles["icon"]}>
                  <PeopleIcon />
                </Box>
              </Box>

              <Box>
                <Typography className={styles["last-update-text"]}> 지난주 대비</Typography>

                <Box>
                  {(history.data?.totalUsers || 0) - (history.lastWeekData?.totalUsers || 0) >= 0 ? (
                    <NorthIcon sx={{ color: "#f00", fontSize: "20px" }} />
                  ) : (
                    <SouthIcon sx={{ color: "#00f", fontSize: "20px" }} />
                  )}

                  <Typography
                    sx={{ color: (history.data?.totalUsers || 0) - (history.lastWeekData?.totalUsers || 0) >= 0 ? "#f00" : "#00f" }}
                  >
                    {Math.abs((history.data?.totalUsers || 0) - (history.lastWeekData?.totalUsers || 0)) + "명"}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid className={styles["card-grid"]} item xs={12} sm={6} lg={4} xl={3}>
            <Box className={styles.card}>
              <Box>
                <Box>
                  <Typography className={styles.title} sx={{ color: "rgb(102, 112, 133)" }}>
                    구독결제 이용 유저
                  </Typography>

                  <Typography className={styles.value}>{(history.data?.subscribedUsers || 0) + " 명"}</Typography>
                </Box>
                <Box className={styles["icon"]} sx={{ bgcolor: "#fb9c0c" }}>
                  <SubscriptionsIcon />
                </Box>
              </Box>

              <Box>
                <Typography className={styles["last-update-text"]}> 지난주 대비</Typography>

                <Box>
                  {(history.data?.subscribedUsers || 0) - (history.lastWeekData?.subscribedUsers || 0) >= 0 ? (
                    <NorthIcon sx={{ color: "#f00", fontSize: "20px" }} />
                  ) : (
                    <SouthIcon sx={{ color: "#00f", fontSize: "20px" }} />
                  )}

                  <Typography
                    sx={{
                      color: (history.data?.subscribedUsers || 0) - (history.lastWeekData?.subscribedUsers || 0) >= 0 ? "#f00" : "#00f",
                    }}
                  >
                    {Math.abs((history.data?.subscribedUsers || 0) - (history.lastWeekData?.subscribedUsers || 0)) + "명"}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid className={styles["card-grid"]} item xs={12} sm={6} lg={4} xl={3}>
            <Box className={styles.card}>
              <Box>
                <Box>
                  <Typography className={styles.title} sx={{ color: "rgb(102, 112, 133)" }}>
                    기간결제 이용 유저
                  </Typography>

                  <Typography className={styles.value}> {(history.data?.periodUsers || 0) + " 명"}</Typography>
                </Box>
                <Box className={styles["icon"]} sx={{ bgcolor: "#fb9c0c" }}>
                  <CalendarMonthIcon />
                </Box>
              </Box>

              <Box>
                <Typography className={styles["last-update-text"]}> 지난주 대비</Typography>

                <Box>
                  {(history.data?.periodUsers || 0) - (history.lastWeekData?.periodUsers || 0) >= 0 ? (
                    <NorthIcon sx={{ color: "#f00", fontSize: "20px" }} />
                  ) : (
                    <SouthIcon sx={{ color: "#00f", fontSize: "20px" }} />
                  )}
                  <Typography
                    sx={{ color: (history.data?.periodUsers || 0) - (history.lastWeekData?.periodUsers || 0) >= 0 ? "#f00" : "#00f" }}
                  >
                    {Math.abs((history.data?.periodUsers || 0) - (history.lastWeekData?.periodUsers || 0)) + "명"}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid className={styles["card-grid"]} item xs={12} sm={6} lg={4} xl={3}>
            <Box className={styles.card}>
              <Box>
                <Box>
                  <Typography className={styles.title} sx={{ color: "rgb(102, 112, 133)" }}>
                    월 매출
                  </Typography>

                  <Typography className={styles.value}> {(history.data?.totalOrders || 0).toLocaleString("ko-KR") + " 원"}</Typography>
                </Box>
                <Box className={styles["icon"]} sx={{ bgcolor: "#635bff" }}>
                  <AttachMoneyIcon />
                </Box>
              </Box>

              <Box>
                <Typography className={styles["last-update-text"]}> 지난달 대비</Typography>

                <Box>
                  {(history.data?.totalOrders || 0) - (history.lastWeekData?.totalOrders || 0) >= 0 ? (
                    <NorthIcon sx={{ color: "#f00", fontSize: "20px" }} />
                  ) : (
                    <SouthIcon sx={{ color: "#00f", fontSize: "20px" }} />
                  )}

                  <Typography
                    sx={{ color: (history.data?.totalOrders || 0) - (history.lastWeekData?.totalOrders || 0) >= 0 ? "#f00" : "#00f" }}
                  >
                    {Math.abs((history.data?.totalOrders || 0) - (history.lastWeekData?.totalOrders || 0)).toLocaleString("ko-KR") + "원"}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  };

  return <Layout children={<Content />} />;
}

export default DashBoardPage;
