import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import HistoryIcon from "@mui/icons-material/History";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ReceiptIcon from "@mui/icons-material/Receipt";

import Layout from "components/Layout";
import detectMobileDevice from "utils/detectMobileDevice";
import classes from "./styles.module.css";
import * as API from "utils/api";
import { useMediaQuery } from "react-responsive";

export default () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const isMobile = detectMobileDevice(navigator.userAgent);

  const [userInfo, setUserInfo] = useState({});
  const [planHistory, setPlanHistory] = useState([]);

  const goBackToCustomers = () => {
    navigate("/customers");
  };

  useEffect(() => {
    API.GET_USER(userId).then((res) => {
      const { user } = res.data;

      let subscription = undefined;

      if (user.plan === "basic") {
        if (user.planType === "monthly") {
          subscription = "베이직 (월간 결제)";
        } else if (user.planType === "yearly") {
          subscription = "베이직 (연간 결제)";
        } else if (user.planType === "period") {
          subscription = "베이직 (기간 선택 결제)";
        }
      } else if (user.plan === "basic_yearly") {
        subscription = "베이직 (연간 결제)";
      } else if (user.plan === "basic_monthly") {
        subscription = "베이직 (월간 결제)";
      } else if (user.plan === "free") {
        subscription = "무료 플랜";
      }

      setUserInfo({
        name: user.name,
        email: user.email,
        company: user.company,
        phone: user.phone,
        subscription,
        createdAt: user.createdAt,
        StartedAt: user.StartedAt,
        ExpiredAt: user.ExpiredAt,
      });
    });

    API.GET_USER_PLAN_HISTORY(userId).then((res) => {
      const { billings } = res.data;

      billings.reverse();

      setPlanHistory(billings);
    });
  }, []);

  return (
    <Layout>
      <Container className={classes.container} disableGutters maxWidth={"lg"}>
        <Button className={classes.back_btn} onClick={goBackToCustomers}>
          <KeyboardBackspaceIcon />
          <Typography>뒤로가기</Typography>
        </Button>

        <Grid container rowSpacing="20px">
          <Grid item xs={12}>
            <Box className={classes.customer_info}>
              <Box>
                <Avatar className={classes.avatar} />

                <Box>
                  <Typography className={classes.name}>{userInfo.name || "-"}</Typography>
                  <Typography className={classes.email}>{userInfo.email || "-"}</Typography>
                </Box>
              </Box>

              {/* <Button>hello</Button> */}
            </Box>
          </Grid>

          <Grid item xs={12} sm={4} md={12} lg={4}>
            <Box className={classes.card} id={classes.details}>
              <Box className={classes.header}>
                <Box className={classes.icon}>
                  <PersonOutlineOutlinedIcon />
                </Box>

                <Typography>기본 정보</Typography>
              </Box>

              <Box>
                <Typography className={classes.key}>이름</Typography>
                <Typography className={classes.value}>{userInfo.name || "-"}</Typography>
              </Box>

              <Box>
                <Typography className={classes.key}>이메일</Typography>
                <Typography className={classes.value}>{userInfo.email || "-"}</Typography>
              </Box>

              <Box>
                <Typography className={classes.key}>회사</Typography>
                <Typography className={classes.value}>{userInfo.company || "-"}</Typography>
              </Box>

              <Box>
                <Typography className={classes.key}>연락처</Typography>
                <Typography className={classes.value}>{userInfo.phone || "-"}</Typography>
              </Box>

              <Box>
                <Typography className={classes.key}>구독 정보</Typography>
                <Typography className={classes.value}>{userInfo.subscription || "-"}</Typography>
                <Typography className={classes.period}>
                  {userInfo.subscription === "무료 플랜"
                    ? `${userInfo.StartedAt || "-"} ~ `
                    : `${userInfo.StartedAt || "-"} ~ ${userInfo.ExpiredAt || ""}`}
                </Typography>
              </Box>

              <Box>
                <Typography className={classes.key}>가입 일자</Typography>
                <Typography className={classes.value}>{userInfo.createdAt || "-"}</Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={8} md={12} lg={8}>
            <Box className={classes.card}>
              <Box className={classes.header}>
                <Box className={classes.icon}>
                  <SubscriptionsIcon />
                </Box>

                <Typography>상품 결제 정보</Typography>
              </Box>

              <SubscriptionTable data={planHistory} />
            </Box>
          </Grid>

          <Grid item xs={12} sm={8} md={12} lg={8}>
            <Box className={classes.card}>
              <Box className={classes.header}>
                <Box className={classes.icon}>
                  <HistoryIcon />
                </Box>

                <Typography>사용 이력</Typography>
              </Box>

              <Box>
                <HistoryTable />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
};

const HistoryTable = () => {
  const headRef = useRef(null);
  const ContainerRef = useRef(null);

  const [rowHeight, setRowHeight] = useState(null);
  const [bodyHeight, setBodyHeight] = useState(undefined);

  useEffect(() => {
    // setRowHeight(ref.current?.clientHeight / 5 || undefined);
    setBodyHeight(ContainerRef.current.clientHeight - headRef.current.clientHeight);
  }, []);

  return (
    <TableContainer className={classes.table} id={classes.history} ref={ContainerRef}>
      <Table>
        <TableHead ref={headRef}>
          <TableRow>
            <TableCell>콘텐츠</TableCell>
            <TableCell>날짜</TableCell>
            <TableCell align="right">접속 인원</TableCell>
          </TableRow>
        </TableHead>

        {false && (
          <TableBody>
            {new Array(5).fill(0).map((_, idx) => (
              <TableRow key={idx} sx={{ height: (bodyHeight - 40) / 5 || "auto" }}>
                <TableCell>1</TableCell>
                <TableCell>1</TableCell>
                <TableCell>1</TableCell>
              </TableRow>
            ))}

            <TableRow>
              <TableCell colSpan={3} className={classes.action_row}>
                <Box>
                  <IconButton>
                    <KeyboardArrowLeftIcon />
                  </IconButton>

                  <Typography>1 / 5</Typography>

                  <IconButton>
                    <KeyboardArrowRightIcon />
                  </IconButton>
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        )}

        {true && (
          <TableBody sx={{ height: bodyHeight || "auto" }}>
            <TableRow>
              <TableCell colSpan={3} className={classes.empty_cell}>
                <Box>
                  <Typography>데이터가 없습니다.</Typography>
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};

const SubscriptionTable = ({ data }) => {
  const headRef = useRef(null);
  const ContainerRef = useRef(null);
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const [rowHeight, setRowHeight] = useState(null);
  const [bodyHeight, setBodyHeight] = useState(undefined);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [slicedData, setSlicedData] = useState([]);

  const handleSubsPage = (e) => {
    const id = e.currentTarget.id;

    if (id === "prev") {
      setPage((prev) => prev - 1);
    } else if (id === "next") {
      setPage((prev) => prev + 1);
    }
  };

  useEffect(() => {
    // setRowHeight(ref.current?.clientHeight / 5 || undefined);
    setBodyHeight(ContainerRef.current.clientHeight - headRef.current.clientHeight);
  }, []);

  useEffect(() => {
    if (data.length === 0) return;

    const start = (page - 1) * 5;
    const end = start + 5;

    let slicedData = data.slice(start, end);

    if (slicedData.length < 5) {
      const diff = 5 - slicedData.length;

      for (let i = 0; i < diff; i++) {
        slicedData.push({ _id: i });
      }
    }

    setSlicedData(slicedData);
    setTotalPage(Math.ceil(data.length / 5));
  }, [data, page]);

  return (
    <Box>
      <TableContainer className={classes.subscription} ref={ContainerRef}>
        <Table>
          <TableHead ref={headRef}>
            <TableRow>
              <TableCell align="center">상품명</TableCell>
              <TableCell align="center">결제일</TableCell>
              <TableCell>금액</TableCell>
              <TableCell width={45} align="right">
                영수증
              </TableCell>
            </TableRow>
          </TableHead>

          {data.length !== 0 && (
            <TableBody>
              {slicedData.map((row) => (
                <TableRow
                  key={row._id}
                  className={row.description ? "" : classes.empty_row}
                  sx={!isMobile && { height: (bodyHeight - 40) / 5 || "auto" }}
                >
                  <TableCell data-label="상품명" width={isMobile ? "100%" : "auto"}>
                    {row.description?.replace("위드플러스 라이브", "")}
                  </TableCell>

                  <TableCell align={isMobile ? "left" : "center"} data-label="결제일" width={isMobile ? "100%" : "auto"}>
                    {row.approvedAt?.slice(0, 10)}
                  </TableCell>

                  <TableCell align={isMobile ? "left" : "center"} data-label="금액" width={isMobile ? "60%" : "auto"}>
                    {row.totalAmount !== undefined && row.totalAmount?.toLocaleString("ko-KR") + "원"}
                  </TableCell>

                  <TableCell align="right" width={isMobile ? "40%" : "auto"} data-label="영수증">
                    {row.receipt && (
                      <IconButton href={row.receipt} target="_blank" sx={{ p: 0 }}>
                        <ReceiptIcon />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}

              <TableRow>
                <TableCell colSpan={4} className={classes.action_row}>
                  <Box>
                    <IconButton disabled={page === 1} onClick={handleSubsPage} id="prev">
                      <KeyboardArrowLeftIcon />
                    </IconButton>

                    <Typography>
                      {page} / {totalPage}
                    </Typography>

                    <IconButton disabled={page === totalPage} onClick={handleSubsPage} id="next">
                      <KeyboardArrowRightIcon />
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          )}

          {data.length === 0 && (
            <TableBody sx={{ height: bodyHeight || "auto" }}>
              <TableRow>
                <TableCell colSpan={4} className={classes.empty_cell}>
                  <Box>
                    <Typography>데이터가 없습니다.</Typography>
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </Box>
  );
};
