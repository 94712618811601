import React, {useState, useEffect} from "react";
import { useNavigate} from 'react-router-dom';

import logo from '../../assets/images/withplus Logo.png'
import { socket } from '../../utils/socket';

import { 
    Button, Grid, TextField, FormHelperText, Container, Avatar, Box, Typography, Link, FormControlLabel, Checkbox
} from '@mui/material'

import { deepPurple } from '@mui/material/colors';

import HowToRegIcon from '@mui/icons-material/HowToReg';



const RegisterPage = (props) => {
    const navigate = useNavigate()
    const [loginType, setloginType] = useState("");
    const [name, setname] = useState("");
    const [email, setemail] = useState("");
    const [emailCheck, setEmailCheck] = useState("");
    const [emailCheckStyle, setEmailCheckStyle] = useState({display:"none"});
    const [password, setpassword] = useState("");
    const [checkPassword, setcheckPassword] = useState("");
    const [style, setstyle] = useState({display: 'none'});
    const [checkBox, setCheckBox] = useState([false, false]);

    const handleSubmit = (event) => {
        event.preventDefault();
        if (loginType === "" || emailCheck === "사용중입니다."){
            alert("회원가입에 실패하였습니다.")
            return;
        }
        socket.emit("signup", {loginType: loginType, name: name, email: email, password: password})
    }
    const handleName = (e) => {
        setname(e.target.value)
    }
    const handleEmail = (e) => {
        setemail(e.target.value)
    }
    const handlePassword = (e) => {
        setpassword(e.target.value)
    }
    const handleCheckPassword = (e) => {
        setcheckPassword(e.target.value)
    }
    const handleChangeSuperAdmin = (e) => {
        if(!e.target.checked){
            let newArr = [...checkBox]
            newArr[0] = false
            setCheckBox(newArr)
        }else{
            setCheckBox([e.target.checked, !e.target.checked]);
        }
    }
    const handleChangeAdmin = (e) => {
        if(!e.target.checked){
            let newArr = [...checkBox]
            newArr[1] = false
            setCheckBox(newArr)
        }else{
            setCheckBox([!e.target.checked, e.target.checked]);
        }
    }
    const checkEmail = () => {
        if(email === "") return;
        socket.emit("checkEmail", {email: email})
    }
    useEffect(() => {
        if (password === checkPassword || checkPassword === "") {
            setstyle({ display: 'none', color:'red'})
        } else { 
            setstyle({ display: 'block', color:'red' })
        }
    }, [password, checkPassword]);
    useEffect(() => {
        if(checkBox[0]) setloginType("SuperAdmin")
        else if(checkBox[1])setloginType("Admin")
        else setloginType("")
    }, [checkBox]);
    useEffect(() => {
        socket.on("checkEmail", (data) => {
            setEmailCheck(data.message)
            if(data.success){
                setEmailCheckStyle({width:"100%", fontSize:"14px", color:"#00d084"})
            }else{
                setEmailCheckStyle({width:"100%", fontSize:"14px", color:"#cf2e2e"})
            }
        })
    
        socket.on("signup", (data) => {
            if(data.success){
                alert("회원가입에 성공하였습니다.")
                navigate("/login")
            }
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <img src={logo} width='20%' height='20%' alt={"withplus"}/>
            <Container maxWidth="xs">
                <Box className="box" onSubmit={handleSubmit} component="form" sx={{ mt: 1, alignItems: 'center', flexDirection: 'column', display: 'flex', }}>
                    <Avatar sx={{m: 1, bgcolor: deepPurple[500], width:56, height:56}}>
                        <HowToRegIcon></HowToRegIcon>
                    </Avatar>
                    <Typography component="h1" variant="h5" sx={{py:1}}>
                        회원가입
                    </Typography>
                    <Box sx={{width:'100%', borderRadius: '10px', border:1, borderColor:'rgba(0,0,0,0.2)'}}>
                        <FormHelperText error={false} sx={{textAlign:'center', fontSize:"14px", color:"black"}}>{"회원가입 유형을 선택해주세요."}</FormHelperText>
                        <Grid container sx={{px:1}}>
                            <Grid item xs sx={{px:4}}>
                                <FormControlLabel
                                    label="SuperAdmin"
                                    control={
                                        <Checkbox 
                                            checked={checkBox[0]}
                                            onChange={handleChangeSuperAdmin}/>}
                                />
                            </Grid>
                            <Grid item xs sx={{px:4}}>
                                <FormControlLabel
                                    label="Admin"
                                    control={
                                        <Checkbox
                                            checked={checkBox[1]}
                                            onChange={handleChangeAdmin}/>}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <TextField
                        type="text"
                        margin="normal"
                        fullWidth
                        label="이름"
                        required
                        onChange={e => handleName(e)}
                    ></TextField>
                    <Grid container  spacing={1} alignItems="center">
                        <Grid item xs >
                        <TextField
                            type="text"
                            margin="normal"
                            fullWidth
                            label="이메일"
                            required
                            onChange={e => handleEmail(e)}
                            
                        ></TextField>
                        </Grid>
                        <Grid item sx={{px:1}} ><Button variant="contained" onClick={checkEmail}>check</Button></Grid>
                    </Grid>
                    <FormHelperText sx={emailCheckStyle}>{emailCheck}</FormHelperText>
                    <TextField
                        type="password"
                        margin="normal"
                        fullWidth
                        label="패스워드"
                        required
                        onChange={e => handlePassword(e)}
                    ></TextField>
                    <TextField
                        type="password"
                        margin="normal"
                        fullWidth
                        label="패스워드 확인"
                        required
                        onChange={e => handleCheckPassword(e)}
                    ></TextField>
                    <div style={style}>비밀번호가 틀립니다.</div>
                    <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    >
                    회원가입
                    </Button>
                    <Grid container>
                        <Grid item>
                            <Link className="test" href="/login">계정이 있으신가요? 로그인 하러가기</Link>
                        </Grid>

                    </Grid>

                </Box>
            </Container>
        </>
    )
}

export default RegisterPage;