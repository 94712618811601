import React, { useEffect, useState } from "react";

import { TableRow, TableCell, IconButton, Box, Typography } from "@mui/material";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import PendingOutlinedIcon from "@mui/icons-material/PendingOutlined";
import detectMobileDevice from "utils/detectMobileDevice";
import classes from "./AWS_Setting.module.css";

const AWS_Setting_Table = (props) => {
  const isMobile = detectMobileDevice(window.navigator.userAgent);

  const [name, setName] = useState("");
  const [status, setStatus] = useState("");
  const [statusIcon, setStatusIcon] = useState(null);
  /*더 보기 클릭 시 드롭다운 나오는 이벤트핸들러*/
  const handleMoreClick = (event) => {
    props.handleMoreClick(event);
    /*id값을 받아 삭제 테이블 삭제 시 사용*/
    props.setSelectedOption(props.server);
  };

  useEffect(() => {
    switch (props.server) {
      case "Live-Polling-dev":
        setName("Live Polling");
        break;
      case "Great-Win-dev":
        setName("Great Win");
        break;
      case "Challenge24-AR-dev":
        setName("챌린지 24");
        break;
      case "Great-Deal-dev":
        setName("Great Deal");
        break;
      case "Great-Pick-dev":
        setName("Great Pick");
        break;
      case "Governance6-dev":
        setName("퍼블리카");
        break;
      case "sbmmobile-dev":
        setName("스마트 비 마스터스 (모바일 / 대면)");
        break;
      case "sbmserver-dev":
        setName("스마트 비 마스터스 (PC / 비대면)");
        break;
    }

    switch (props.status) {
      case "CREATE_COMPLETE":
        setStatus("실행 중");
        break;
      case "DELETE_IN_PROGRESS":
        setStatus("종료 중");
        break;
      case "CREATE_IN_PROGRESS":
        setStatus("서버 생성중");
        break;
    }
  }, [props]);

  useEffect(() => {
    if (status === "실행 중") setStatusIcon(<CheckCircleOutlineIcon />);
    else setStatusIcon(<PendingOutlinedIcon />);
  }, [status]);

  return (
    <>
      <TableRow>
        <TableCell width={100} sx={{ wordBreak: "keep-all" }}>
          {name}
        </TableCell>
        <TableCell width={120}>
          <Box
            sx={{
              display: isMobile ? "block" : "flex",
              alignItems: "center",
              gap: "10px",
              color: status === "실행 중" ? "green" : "#666666",
            }}
          >
            {statusIcon}
            <Typography sx={{ wordBreak: "keep-all", fontSize: "14px" }}>{status}</Typography>
          </Box>
        </TableCell>
        <TableCell width={80}>{props.type}</TableCell>
        <TableCell size="small" sx={{ p: 0, width: "clamp(28px, 9vw, 40px)" }}>
          <IconButton onClick={handleMoreClick} disabled={status !== "실행 중"} size="small">
            <MoreVertIcon sx={{ fontSize: "clamp(17px, 5.41vw, 24px)" }} />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};

export default AWS_Setting_Table;
