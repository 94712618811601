import io from "socket.io-client";

const environment = process.env.REACT_APP_NODE_ENV;

// * 현재 연결된 와이파이 ip로 설정
export const socket = io.connect(
  // environment === "development" ? "http://localhost" : "https://plussol.wplusedu.co.kr",
  // environment === "development" ? "http://192.168.0.55" : "https://plussol.wplusedu.co.kr",
  // environment === "development" ? "https://local.cgcloud.kr" : "https://960b2dece63e.ngrok.app/",
  environment === "development" ? "https://local.cgcloud.kr" : "https://plussol.wplusedu.co.kr",
  //

  { cors: { origin: "*", reconnection: false } }
);

export const withplusLiveSocket = io.connect(
  // environment === "development" ? "http://localhost" : "https://www.withplus.live",
  // environment === "development" ? "http://192.168.0.55" : "https://www.withplus.live",
  // environment === "development" ? "https://local.cgcloud.kr" : "https://960b2dece63e.ngrok.app/",
  environment === "development" ? "https://local.cgcloud.kr" : "https://www.withplus.live",
  // environment === "development" ? "https://www.withplus.live" : "https://960b2dece63e.ngrok.app/",
  { cors: { origin: "*", reconnection: false } }
);
// export const socket = null;
