import Axios from "axios";

const environment = process.env.REACT_APP_NODE_ENV;

const axios = Axios.create({
  baseURL: environment === "development" ? "" : "https://plussol.wplusedu.co.kr",
});

export const GET_USERS = ({ page, plan, name }) => {
  return new Promise((resolve, reject) => {
    axios.get("/api/v1/users", { params: { page, plan, name } }).then((res) => {
      resolve(res);
    });
  });
};

export const GET_USER = (id) => {
  return new Promise((resolve, reject) => {
    axios.get(`/api/v1/user/${id}`).then((res) => {
      resolve(res);
    });
  });
};

export const GET_LIVE_HISTORY = () => {
  return new Promise((resolve, reject) => {
    axios.get("/api/liveHistory").then((res) => {
      resolve(res);
    });
  });
};

export const GET_USER_PLAN_HISTORY = (id) => {
  return new Promise((resolve, reject) => {
    axios.get(`/api/billingHistory`, { params: { auth: id } }).then((res) => {
      resolve(res);
    });
  });
};
