import React, { useState, useEffect }from "react";
import { useNavigate } from 'react-router-dom';
import { useCookies} from 'react-cookie';

import Layout from './../components/Layout';
import ConfirmDialog from './../components/dialog';
import { socket } from '../utils/socket';

import {
    Grid, Box, Divider, FormControl, InputLabel, Select, MenuItem, OutlinedInput, TextField, IconButton, Button,
    Typography, InputAdornment, 
} from '@mui/material';

import  {MobileDatePicker}  from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';


function ApplyPage(props) {
    const today = new Date()
    const [gameList, setGameList] = useState([]);
    const [cookies] = useCookies();
    const [gameId, setGameId] = useState("");
    const [expireDate, setExpireDate] = useState(today);
    const [people, setPeople] = useState(0);
    const navigate = useNavigate()
    

    const Content = () => {
        const [open, setOpen] = useState(false);
        const [confirm, setConfirm] = useState(false);
        const confirmTitle = "게임 구독을 신청하시겠습니까?"
        const confirmContent = "구독을 신청하고 최고 관리자의 승인 후에 구독 권한이 부여됩니다."

        const handleChangeGame = (e) => {
            setGameId(e.target.value)
        }

        const handleCalendar = () => {
            setOpen(!open)
        }

        const handleSubmit = (event) => {
            if(gameId === "" || people === 0) return;
            setConfirm(true)
        }

        const handlePeople = (e) => {
            setPeople(e.target.value)
        }

        const handleConfirm = (e) => {
            var value = (e.target.value === "true")
            setConfirm(false)
            if(value){
                socket.emit("applyGame", {gameId:gameId, expireDate:expireDate, people:people, token:cookies.auth})
            }
        }

        return(
            <Grid container display="flex" alignItems= 'center' justifyContent="center" sx={{py:2, height:"500px"}}>
                <Grid item xs={8} md={6} lg={4}>
                <Box alignItems="center" justifyContent="center" display="flex" sx={{border:1, borderRadius:"10px", width:"100%", backgroundColor:"#eeeeee"}}>
                    <Box sx={{width:"100%"}}>
                    <Typography align="center" sx={{my:1}}> 게임 추가</Typography>
                    <Divider sx={{width:"100%", my:1}}/>
                    <FormControl fullWidth size='small' sx={{my:1}}>
                        <InputLabel id='select-game'>게임</InputLabel>
                        <Select
                            labelId='select-game'
                            id="game-select"
                            value={gameId}
                            onChange={handleChangeGame}
                            input={<OutlinedInput label="게임"/>}>
                            {gameList.map((game, idx) => (
                                <MenuItem key={idx} value={game._id}>{game.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <MobileDatePicker
                            label="만료 날짜"
                            open={open}
                            onOpen={handleCalendar}
                            onClose={handleCalendar}
                            inputFormat="yyyy/MM/dd"
                            value={expireDate}
                            onChange={(newExpireDate) => {
                                setExpireDate(newExpireDate)
                            }}
                            renderInput={(params) => <TextField {...params} size="small" sx={{width:'100%', my:1}}
                                                        InputProps={{
                                                            endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton onClick={handleCalendar} edge="end">
                                                                <CalendarMonthIcon />
                                                                </IconButton>
                                                            </InputAdornment>
                                                            ),
                                                        }}/>}
                        />
                    </LocalizationProvider>
                    <TextField fullWidth size="small" type="number" label="접속 가능 인원 (명)" sx={{my:1}} onChange={handlePeople}></TextField>
                    <Button onClick={handleSubmit} fullWidth variant="contained" sx={{py:1, backgroundColor:"#222222"}}>신청</Button>
                    </Box>
                </Box>
                </Grid>
                <ConfirmDialog open={confirm} handleClose={handleConfirm} title={confirmTitle} content={confirmContent}/>
            </Grid>
        )
    }

    useEffect(() => {
        socket.emit("getGameList")

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {

        socket.on("getGameList", (data) => {
            setGameList(data)
        })

        socket.on("applyGame", (data) => {
            if(data.success){
                alert("신청이 완료되었습니다.")
                navigate(0);
            }else{
                alert(data.message);
            }
          })

        return () => {
            socket.off("getGameList")
            socket.off("applyGame")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Layout children = {Content()}/>
    );
}

export default ApplyPage;