import React, { useEffect, useState } from "react";

import {
  Box,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Select,
  MenuItem,
  Button,
  Grid,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker as Datepicker } from "@mui/x-date-pickers/DatePicker";
import "dayjs/locale/ko";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import Layout from "components/Layout";
import classes from "./CouponSettingPage.module.css";
import * as S from "./styles";
import { withplusLiveSocket as socket } from "utils/socket";
import detectMobileDevice from "utils/detectMobileDevice";

const modalBoxStyled = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#fff",
  padding: "30px",
  boxSizing: "border-box",
  borderRadius: "15px",
  width: "600px",
  maxHeight: "80vh",
  outline: 0,
  overflowY: "auto",
  overflowX: "hidden",
};

const plans = [
  {
    id: "basic-monthly",
    name: "베이직 (월간 결제)",
  },
  {
    id: "basic-yearly",
    name: "베이직 (연간 결제)",
  },
  {
    id: "group-basic-yearly",
    name: "[그룹] 베이직 (연간 결제)",
  },
  {
    id: "basic-period",
    name: "베이직 (기간 선택 결제)",
  },
];

const CouponSettingPage = (props) => {
  const isMobile = detectMobileDevice(navigator.userAgent);

  const [couponModalOpened, setCouponModalOpened] = useState(false);
  const [coupons, setCoupons] = useState([]);
  const [target, setTarget] = useState(null);

  const handleCouponModal = () => {
    setCouponModalOpened(!couponModalOpened);
  };

  const handleCouponModalWithModify = (e) => {
    handleCouponModal();
    setTarget(coupons.filter((coupon) => coupon._id === e.target.id)[0]);
  };

  const deleteCoupon = (e) => {
    if (window.confirm("정말 삭제하시겠습니까?")) {
      socket.emit("deleteCoupon", e.target.id);
    }
  };

  useEffect(() => {
    socket.on("saveCoupon", (data) => {
      alert("쿠폰이 저장되었습니다.");
      window.location.reload();
    });

    socket.on("getCouponList", (data) => {
      console.log(data);
      setCoupons(data);
    });

    socket.on("deleteCoupon", (data) => {
      alert("쿠폰이 삭제되었습니다.");
      window.location.reload();
    });

    socket.on("modifyCoupon", (data) => {
      alert("쿠폰이 수정되었습니다.");
      window.location.reload();
    });

    return () => {
      socket.off("saveCoupon");
      socket.off("getCouponList");
      socket.off("deleteCoupon");
      socket.off("modifyCoupon");
    };
  });

  useEffect(() => {
    socket.emit("getCouponList");
  }, []);

  useEffect(() => {
    if (!couponModalOpened) setTarget(null);
  }, [couponModalOpened]);
  return (
    <Layout>
      <Box className={classes.container}>
        <Box className={classes.header}>
          <h1> 위드플러스 라이브 쿠폰 관리</h1>

          <button className={classes.button} onClick={handleCouponModal}>
            + 쿠폰 만들기
          </button>
        </Box>

        <Box>
          {(() => {
            const props = { coupons, modify: handleCouponModalWithModify, deleteCoupon };

            return isMobile ? <CouponCards {...props} /> : <CouponTable {...props} />;
            // return !isMobile ? <CouponCards {...props} /> : <CouponTable {...props} />;
          })()}
        </Box>
      </Box>

      {couponModalOpened && <CouponModal open={couponModalOpened} onClose={handleCouponModal} target={target} />}
    </Layout>
  );
};

const CouponCards = ({ coupons, modify, deleteCoupon }) => {
  return (
    <Box className={classes.card_container}>
      {coupons.map((coupon) => (
        <Box className={classes.card} key={coupon._id}>
          <Box>{coupon.content.couponName}</Box>
          <Grid container>
            <Grid item xs={6}>
              쿠폰 할인 내용
            </Grid>

            <Grid item xs={6}>
              {coupon.content.discountApplication === "once" && "첫 정기결제시 "}
              {coupon.content.discountApplication === "every" && "매 정기결제마다 "}
              {coupon.content.discountType === "percentage" && coupon.content.discount + "%"}
              {coupon.content.discountType === "fixed" && coupon.content.discount + "원"}
              할인
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={6}>
              적용되는 구독 플랜
            </Grid>

            <Grid item xs={6}>
              {coupon.content.applicatedPlans.map((plan) => (
                <Box key={plan} sx={{ mb: "5px", fontSize: "clamp(13px, 4.12vw, 18.3px)" }}>
                  {plans.find((item) => item.id === plan).name}
                </Box>
              ))}
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={6}>
              사용내역
            </Grid>

            <Grid item xs={6}>
              {coupon.usedUserList.length}건
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={6}>
              유효기간
            </Grid>

            <Grid item xs={6}>
              {coupon.content.unlimited ? "무제한" : coupon.content.expiredDate + " 23:59:59"}
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={6}>
              쿠폰코드
            </Grid>

            <Grid item xs={6}>
              {coupon.code}
            </Grid>
          </Grid>
          <Box>
            <Button variant="contained" onClick={deleteCoupon} id={coupon._id}>
              삭제
            </Button>
            <Button variant="contained" onClick={modify} id={coupon._id}>
              수정
            </Button>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

const CouponTable = ({ coupons, modify, deleteCoupon }) => {
  return (
    <Table>
      <TableHead>
        <TableRow style={{ backgroundColor: "#dee2e6" }}>
          <TableCell>쿠폰명</TableCell>
          <TableCell>쿠폰 할인 내용</TableCell>
          <TableCell>적용되는 구독플랜</TableCell>
          <TableCell>사용내역</TableCell>
          <TableCell>유효기간</TableCell>
          <TableCell>쿠폰코드</TableCell>
          <TableCell>관리기능</TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {coupons.map((coupon) => (
          <TableRow key={coupon._id}>
            <TableCell>{coupon.content.couponName}</TableCell>
            <TableCell>
              {coupon.content.discountApplication === "once" && "첫 정기결제시 "}
              {coupon.content.discountApplication === "every" && "매 정기결제마다 "}
              {coupon.content.discountType === "percentage" && coupon.content.discount + "%"}
              {coupon.content.discountType === "fixed" && coupon.content.discount + "원"}
              할인
            </TableCell>
            <TableCell>
              {coupon.content.applicatedPlans.map((plan) => (
                <Box
                  key={plan}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    color: "#666666",
                  }}
                >
                  {plans.find((item) => item.id === plan).name}
                </Box>
              ))}
            </TableCell>
            <TableCell>{coupon.usedUserList.length}건</TableCell>
            <TableCell>{coupon.content.unlimited ? "무제한" : coupon.content.expiredDate + " 23:59:59"}</TableCell>
            <TableCell>{coupon.code}</TableCell>
            <TableCell>
              <Box sx={{ display: "flex", gap: "3px", width: "max-content" }}>
                <Button id={coupon._id} onClick={modify}>
                  수정
                </Button>
                <Button sx={{ color: "red" }} onClick={deleteCoupon} id={coupon._id}>
                  삭제
                </Button>
              </Box>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const CouponModal = (props) => {
  const { open, onClose, target } = props;
  const isMobile = detectMobileDevice(navigator.userAgent);

  const [couponName, setCouponName] = useState("");
  const [couponExplain, setCouponExplain] = useState("");
  const [expireDate, setExpireDate] = useState(null);
  const [unlimited, setUnlimited] = useState(false);
  const [discountType, setDiscountType] = useState("default");
  const [discount, setDiscount] = useState(0);
  const [discountApplication, setDiscountApplication] = useState("default");
  const [applicatedPlans, setApplicatedPlans] = useState([]);

  const handleDiscountType = (e) => {
    setDiscountType(e.target.value);
  };

  const handleUnlimited = () => {
    setUnlimited(!unlimited);
  };

  const handleCouponName = (e) => {
    setCouponName(e.target.value);
  };

  const handleCouponExplain = (e) => {
    setCouponExplain(e.target.value);
  };

  const handleDiscount = (e) => {
    if (isNaN(e.target.value)) return;

    setDiscount(e.target.value * 1);
  };

  const handleDiscountApplication = (e) => {
    setDiscountApplication(e.target.id);
  };

  const handleApplicatedPlans = (e) => {
    if (applicatedPlans.includes(e.target.id)) {
      setApplicatedPlans(applicatedPlans.filter((plan) => plan !== e.target.id));
    } else {
      setApplicatedPlans([...applicatedPlans, e.target.id]);
    }
  };

  const saveCoupon = () => {
    if (!validateCoupon()) return;

    let expiredDate = unlimited ? null : new Date(expireDate);
    if (!unlimited) {
      expireDate.setHours(9, 0, 0, 0);
    }

    const coupon = {
      couponName,
      couponExplain,
      expiredDate: unlimited ? null : expiredDate.toISOString().slice(0, 10),
      unlimited,
      discountType,
      discount,
      discountApplication,
      applicatedPlans,
    };

    socket.emit("saveCoupon", coupon);
  };

  const modifyCoupon = () => {
    if (!validateCoupon()) return;

    let expiredDate = unlimited ? null : new Date(expireDate);
    if (!unlimited) {
      expireDate.setHours(9, 0, 0, 0);
    }

    const coupon = {
      couponName,
      couponExplain,
      expiredDate: unlimited ? null : expiredDate.toISOString().slice(0, 10),
      unlimited,
      discountType,
      discount,
      discountApplication,
      applicatedPlans,
    };

    socket.emit("modifyCoupon", { coupon, couponId: target._id });
  };

  // 입력값 검증 함수
  const validateCoupon = () => {
    if (couponName === "") {
      alert("쿠폰명을 입력해주세요.");
      return false;
    }

    if (couponExplain === "") {
      alert("쿠폰 내용을 입력해주세요.");
      return false;
    }

    if (discountType === "default") {
      alert("할인 구분을 선택해주세요.");
      return false;
    }

    if (discount === 0) {
      alert("할인율을 입력해주세요.");
      return false;
    }

    if (discountApplication === "default") {
      alert("적용 범위를 선택해주세요.");
      return false;
    }

    if (applicatedPlans.length === 0) {
      alert("적용 구독 플랜을 선택해주세요.");
      return false;
    }

    if (!unlimited && expireDate === null) {
      alert("유효기간을 설정해주세요.");
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (target !== null) {
      setCouponName(target.content.couponName);
      setCouponExplain(target.content.couponExplain);
      setDiscountType(target.content.discountType);
      setDiscount(target.content.discount);
      setDiscountApplication(target.content.discountApplication);
      setApplicatedPlans(target.content.applicatedPlans);
      setUnlimited(target.content.unlimited);
      setExpireDate(target.content.expiredDate === null ? null : new Date(target.content.expiredDate));
    }
  }, [target]);

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={modalBoxStyled} className={isMobile ? classes.modal : null}>
        {/* <Box sx={modalBoxStyled} className={!isMobile ? classes.modal : null}> */}
        <S.ModalTitle>쿠폰 정보</S.ModalTitle>

        <S.ModalContentBox>
          <S.ModalContent>쿠폰명</S.ModalContent>

          <S.ModalInput value={couponName} onChange={handleCouponName} />
        </S.ModalContentBox>

        <S.ModalContentBox>
          <S.ModalContent>쿠폰 내용</S.ModalContent>

          <S.ModalInput value={couponExplain} onChange={handleCouponExplain} />
        </S.ModalContentBox>

        <Box sx={{ display: "flex", justifyContent: "space-between", gap: "10px" }}>
          <S.ModalContentBox>
            <S.ModalContent>할인 구분</S.ModalContent>

            <Select size="small" value={discountType} onChange={handleDiscountType}>
              <MenuItem value="default" disabled sx={{ maxHeight: 0, p: 0, overflow: "hidden" }}>
                할인 구분을 선택해주세요
              </MenuItem>

              <MenuItem value="percentage">정률 (%)</MenuItem>

              <MenuItem value="fixed">정액 (원)</MenuItem>
            </Select>
          </S.ModalContentBox>

          <S.ModalContentBox>
            <S.ModalContent>할인율</S.ModalContent>

            <S.ModalInput
              value={discount}
              onChange={handleDiscount}
              InputProps={{
                endAdornment: (
                  <Typography sx={{ font: "16px NotoSansKR" }}>
                    {discountType === "percentage" && "(%)"}
                    {discountType === "fixed" && "(원)"}
                  </Typography>
                ),
              }}
            />
          </S.ModalContentBox>
        </Box>

        <S.ModalContentBox>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <S.ModalContent>적용 범위</S.ModalContent>

            <Box sx={{ display: "flex" }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <S.ModalCheckbox id="once" checked={discountApplication === "once"} onChange={handleDiscountApplication} />
                <S.ModalCheckboxLabel>첫 정기결제만</S.ModalCheckboxLabel>
              </Box>

              <Box sx={{ display: "flex", alignItems: "center" }}>
                <S.ModalCheckbox id="every" checked={discountApplication === "every"} onChange={handleDiscountApplication} />
                <S.ModalCheckboxLabel>매 정기결제마다</S.ModalCheckboxLabel>
              </Box>
            </Box>
          </Box>

          <S.ModalPlanBox>
            <S.ModalPlanTitle>적용 구독 플랜</S.ModalPlanTitle>

            <S.ModalPlanGridBox>
              {plans.map((plan) => (
                <S.ModalPlanGridItem key={plan.id}>
                  <S.ModalPlanGridItemBox>
                    <S.ModalCheckbox id={plan.id} checked={applicatedPlans.includes(plan.id)} onChange={handleApplicatedPlans} />

                    <S.ModalCheckboxLabel sx={{ font: "14px Pretendard", wordBreak: "keep-all" }}>{plan.name}</S.ModalCheckboxLabel>
                  </S.ModalPlanGridItemBox>
                </S.ModalPlanGridItem>
              ))}
            </S.ModalPlanGridBox>
          </S.ModalPlanBox>
        </S.ModalContentBox>

        <S.ModalContentBox>
          <Box sx={{ display: "flex", gap: "3px", alignItems: "center" }}>
            <S.ModalContent>유효기간</S.ModalContent>

            <Box sx={{ display: "flex", alignItems: "center" }}>
              <S.ModalCheckbox checked={unlimited} onChange={handleUnlimited} />

              <S.ModalCheckboxLabel>무제한</S.ModalCheckboxLabel>
            </Box>
          </Box>

          <DatePicker expireDate={expireDate} setExpireDate={setExpireDate} unlimited={unlimited} />
        </S.ModalContentBox>

        <S.ModalButtonBox>
          <S.ModalButton type="cancel" onClick={onClose}>
            취소하기
          </S.ModalButton>

          <S.ModalButton onClick={target === null ? saveCoupon : modifyCoupon}>{target === null ? "저장하기" : "수정하기"}</S.ModalButton>
        </S.ModalButtonBox>
      </Box>
    </Modal>
  );
};

const DatePicker = (props) => {
  const { expireDate, setExpireDate, unlimited } = props;
  const today = dayjs();
  const [open, setOpen] = useState(false);

  const handleCalendar = () => {
    setOpen(!open);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko">
      <Datepicker
        label={expireDate === null ? "유효 기간을 설정해주세요." : "유효 기간"}
        open={open}
        disabled={unlimited}
        onOpen={handleCalendar}
        onClose={handleCalendar}
        value={expireDate}
        onChange={(newExpireDate) => {
          setExpireDate(newExpireDate.$d);
        }}
        minDate={today}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            sx={{
              width: "100%",
              my: 1,
              bgcolor: unlimited ? "#f5f5f5" : "#fff",
              opacity: unlimited ? "0.5" : "1",
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default CouponSettingPage;
