import React from "react";

import "./Test.css";
import Logo from "./Logo";
import NavItem from "./nav_item";

import { Box, Divider, Grid, Typography, Avatar, Drawer } from "@mui/material";

import GroupIcon from "@mui/icons-material/Group";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import DomainVerificationIcon from "@mui/icons-material/DomainVerification";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LanIcon from "@mui/icons-material/Lan";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
// import GroupIcon from "@mui/icons-material/Group";

const SideBar = (props) => {
  const { drawer, open, handleSideBar, name, status } = props;
  const items = [
    {
      href: "/dashboard",
      icon: <DashboardIcon fontSize="small" />,
      title: "대시보드",
      active: false,
    },
    {
      href: "/customers",
      icon: <GroupIcon fontSize="small" />,
      title: "유저 관리",
      active: true,
    },
    {
      href: "/group",
      icon: <GroupIcon fontSize="small" />,
      title: "그룹 신청 관리",
      active: true,
    },
    {
      href: "/settings/aws",
      icon: <LanIcon fontSize="small" />,
      title: "AWS 서버 관리",
      active: true,
    },
    {
      href: "/withplus-live/coupon",
      icon: <LocalActivityIcon fontSize="small" />,
      title: "위드플러스 라이브 쿠폰 관리",
      active: true,
    },
  ];
  // const items =
  //   status === "SuperAdmin"
  //     ? [
  //         {
  //           href: "/dashboard",
  //           icon: <DashboardIcon fontSize="small" />,
  //           title: "대시보드",
  //           active: false,
  //         },
  //         {
  //           href: "/customers",
  //           icon: <GroupIcon fontSize="small" />,
  //           title: "유저 관리",
  //           active: true,
  //         },
  //         {
  //           href: "/settings/aws",
  //           icon: <LanIcon fontSize="small" />,
  //           title: "AWS 서버 관리",
  //           active: true,
  //         },
  //         {
  //           href: "/withplus-live/coupon",
  //           icon: <LocalActivityIcon fontSize="small" />,
  //           title: "위드플러스 라이브 쿠폰 관리",
  //           active: true,
  //         },
  //       ]
  //     : [
  //         {
  //           href: "/dashboard",
  //           icon: <DashboardIcon fontSize="small" />,
  //           title: "Dashboard",
  //           active: false,
  //         },
  //         {
  //           href: "/subscribe",
  //           icon: <SubscriptionsIcon fontSize="small" />,
  //           title: "구독 리스트",
  //           active: false,
  //         },
  //         {
  //           href: "/apply",
  //           icon: <DomainVerificationIcon fontSize="small" />,
  //           title: "게임 신청",
  //           active: false,
  //         },
  //       ];

  const content = (
    <Box
      sx={{
        height: "100%",
        width: "250px",
        backgroundColor: "#121828",
        // position: "fixed",
        overflow: "scroll",
        overflowX: "hidden",
      }}
    >
      <Box sx={{ width: "100%", px: 3, py: 3 }}>
        <Logo></Logo>
      </Box>
      <Box
        sx={{
          borderRadius: 1,
          backgroundColor: "rgba(255, 255, 255, 0.04)",
          px: 3,
          py: "11px",
          alignItems: "center",
        }}
      >
        <Grid container alignItems="center" justify="center" direction="column">
          <Grid item>
            <Avatar
              sx={{
                width: 60,
                height: 60,
                ml: 1,
                mr: 2,
                my: 1,
              }}
            />
          </Grid>
          <Grid item>
            <Typography variant="subtitle1" color="white">
              {name}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" color="gray">
              Status : {status}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Divider
        sx={{
          borderColor: "#2D3748",
          my: 3,
        }}
      />
      <Box sx={{ flexGrow: 1 }}>
        {items.map((item) => (
          <NavItem key={item.title} icon={item.icon} href={item.href} title={item.title} active={item.active} sidebar={handleSideBar} />
        ))}
      </Box>
      <Divider
        sx={{
          borderColor: "#2D3748",
          my: 3,
        }}
      />
    </Box>
  );

  return (
    <Drawer open={open} onClose={handleSideBar} anchor="left" variant={drawer ? "temporary" : "permanent"}>
      {content}
    </Drawer>
  );
};

export default SideBar;
