import React, { useEffect, useState } from "react";

import { IconButton, TextField, Tooltip, Modal, Box, Typography } from "@mui/material";

import styled from "styled-components";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import classes from "./AWS_Setting.module.css";
import { socket } from "utils/socket";
import detectMobileDevice from "utils/detectMobileDevice";

function AWSSettingModal({ onSelect, onClose }) {
  const [selectedOption, setSelectedOption] = useState("Live Polling");
  const [type, setType] = useState("t3.nano");
  const [password, setPassword] = useState("");
  const [isPasswordVisible, setPasswordVisible] = useState(false);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleType = (e) => {
    setType(e.target.value);
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleCreateClick = () => {
    if (password === "") {
      alert("패스워드를 입력해주세요");
      return;
    } else if (password !== "Dream68!@") {
      // } else if (password !== "1") {
      alert("패스워드가 틀렸습니다.");
      return;
    }
    onSelect({ selectedOption, type });
  };

  const handleClose = () => {
    onClose?.();
  };

  const handlePasswordVisible = () => {
    setPasswordVisible(!isPasswordVisible);
  };

  const submitOnEnter = (e) => {
    if (e.key === "Enter") {
      handleCreateClick();
    }
  };

  // useEffect(() => {
  //   console.table({ selectedOption, type, password });
  // });

  return (
    <Overlay>
      <ModalWrap>
        <Contents>
          <h1>서버 생성하기</h1>
          <div
            style={{
              padding: "15px 0",
              display: "flex",
              flexDirection: "column",
              boxSizing: "border-box",
            }}
          >
            <h2>서버</h2>
            <Select value={selectedOption} onChange={handleOptionChange}>
              <option key="Live Polling" value="Live Polling">
                Live Polling (테스트용)
              </option>
              <option key="Challenge 24" value="Challenge 24">
                챌린지 24
              </option>
              <option key="Great Deal" value="Great Deal">
                Great Deal
              </option>
              <option key="Great Pick" value="Great Pick">
                Great Pick
              </option>
              <option key="Great Win" value="Great Win">
                Great Win
              </option>
              <option key="Smart B Masters Mobile" value="Smart B Masters Mobile">
                Smart B Masters (모바일 / 대면)
              </option>
              <option key="Smart B Masters PC" value="Smart B Masters PC">
                Smart B Masters (PC / 비대면)
              </option>
              <option key="PublicA" value="PublicA">
                퍼블리카
              </option>
            </Select>
            {/*다른 서버 존재 시 옵션 추가 */}
            <h2>서버 타입 및 용량</h2>
            <Select value={type} onChange={handleType}>
              <option key="t3.nano" value="t3.nano">
                t3.nano (2 vCPU, 0.5GB 메모리)
              </option>
              <option key="t3.micro" value="t3.micro">
                t3.micro (2 vCPU, 1GB 메모리)
              </option>
              <option key="t3.small" value="t3.small">
                t3.small (2 vCPU, 2GB 메모리)
              </option>
              <option key="t3.medium" value="t3.medium">
                t3.medium (2 vCPU, 4GB 메모리)
              </option>
              <option key="t3.large" value="t3.large">
                t3.large (2 vCPU, 8GB 메모리)
              </option>
              <option key="t3.xlarge" value="t3.xlarge">
                t3.xlarge (4 vCPU, 16GB 메모리)
              </option>
              <option key="t3.2xlarge" value="t3.2xlarge">
                t3.2xlarge (8 vCPU, 32GB 메모리)
              </option>
            </Select>
            <label htmlFor="password">
              <h2>패스워드</h2>
            </label>

            <PasswordBox>
              <PasswordField
                id="password"
                placeholder="패스워드를 입력해주세요"
                $isPasswordVisible={isPasswordVisible}
                value={password}
                onChange={handlePassword}
                onKeyDown={submitOnEnter}
              />

              <IconButton
                sx={{
                  position: "absolute",
                  top: "50%",
                  right: "10px",
                  transform: "translate(0%, -50%)",
                }}
                onClick={handlePasswordVisible}
              >
                {isPasswordVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </PasswordBox>
          </div>
          <div
            style={{
              display: "flex",
              // width: "300px",
              justifyContent: "space-around",
              margin: "0 auto",
            }}
          >
            <Button onClick={handleClose} id="cancle">
              취소
            </Button>
            <Button onClick={handleCreateClick} id="create">
              생성
            </Button>
          </div>
        </Contents>
      </ModalWrap>
    </Overlay>
  );
}

export const DeleteModal = (props) => {
  const { open, onClose, selectedOption } = props;
  const isMobile = detectMobileDevice(window.navigator.userAgent);

  const [loading, setLoading] = useState(false);
  const [server, setServer] = useState("");

  const closeModal = () => {
    onClose(false);
  };

  const deleteInstance = () => {
    setLoading(true);
    socket.emit("deleteInstance", { stackName: selectedOption });
  };

  useEffect(() => {
    switch (selectedOption) {
      case "Live-Polling-dev":
        setServer("Live Polling");
        break;
      case "Great-Win-dev":
        setServer("Great Win");
        break;
      case "Challenge24-AR-dev":
        setServer("챌린지 24");
        break;
      case "Great-Deal-dev":
        setServer("Great Deal");
        break;
      case "Great-Pick-dev":
        setServer("Great Pick");
        break;
      case "Governance6-dev":
        setServer("퍼블리카");
        break;
      case "sbmmobile-dev":
        setServer("스마트 비 마스터스 (모바일 / 대면)");
        break;
      case "sbmserver-dev":
        setServer("스마트 비 마스터스 (PC / 비대면)");
        break;
      default:
        break;
    }
  }, [selectedOption]);

  return (
    <Modal open={open}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "white",
          borderRadius: "10px",
          p: "30px",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: "30px",
          width: isMobile ? "90%" : "auto",
          // aspectRatio: "1/1",
        }}
      >
        <Box sx={{ display: "flex", gap: "5px", alignItems: "center", flexDirection: isMobile ? "column" : "row" }}>
          <Typography sx={{ font: "bold 28px Pretendard", color: "red", wordBreak: "keep-all" }}>{server}</Typography>
          <Typography sx={{ font: "20px Pretendard", wordBreak: "keep-all" }}>서버를 종료하시겠습니까?</Typography>
        </Box>

        <Typography sx={{ color: "blue", wordBreak: "keep-all" }}>
          종료하려는 서버가 맞는지 다시 한 번 확인 후 종료 버튼을 눌러주세요.
        </Typography>

        <Box sx={{ display: "flex", gap: "10px" }}>
          <Button style={{ width: "100%" }} onClick={closeModal}>
            취소
          </Button>
          <Button style={{ width: "100%", backgroundColor: "#dd0000" }} onClick={deleteInstance}>
            종료
          </Button>
        </Box>

        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            bgcolor: "#00000060",
            borderRadius: "10px",
            width: "100%",
            height: "100%",
            display: loading ? "flex" : "none",
            justifyContent: "center",
            alignItems: "center",
            backdropFilter: "blur(2px)",
          }}
        >
          <Spinner />
        </Box>
      </Box>
    </Modal>
  );
};

const Spinner = () => {
  return (
    <div className={classes["lds-default"]}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};
const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

const ModalWrap = styled.div`
  width: 600px;
  // height: fit-content;
  border-radius: 15px;
  background-color: #fff;
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);

  @media (max-width: 768px) {
    width: 90%;
    height: calc(100% - 200px);
    overflow-y: auto;
    overflow-x: hidden;
  }
`;

const Contents = styled.div`
  width: 100%;
  padding: 50px 30px;
  box-sizing: border-box;
  h1 {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 60px;
    text-align: center;
  }
  h2 {
    margin: 10px 0;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 20px 10px;

    h1 {
      margin-bottom: 20px;
    }
  }
`;
const Button = styled.button`
  font-size: 16px;
  font-weight: bold;
  width: 100px;
  padding: 10px 20px;
  box-sizing: border-box;
  border: none;
  background-color: ${(props) => (props.id === "create" ? "#00aa00" : "#ababab")};
  border-radius: 10px;
  color: white;
  cursor: pointer;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  &:hover {
    background-color: ${(props) => (props.id === "create" ? "#008800" : "#898989")};
  }
`;

const Select = styled.select`
  font-size: 18px;
  padding: 10px;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 10px;
  border: 1px solid #ababab;

  &:hover {
    background-color: #eaeaea;
  }

  option {
    font-size: 18px;
  }
`;

const PasswordBox = styled.div`
  position: relative;
  width: 100%;
`;

// const PasswordField = styled.input.attrs((props) => ({ type: "password" }))`
//   font-size: 18px;
//   width: 100%;
//   box-sizing: border-box;
//   padding: 10px;
//   border-radius: 10px;
//   border: 1px solid #ababab;
//   &:hover {
//     background-color: #eaeaea;
//   }
// `;

const PasswordField = styled.input.attrs((props) => ({
  type: props.$isPasswordVisible ? "text" : "password",
}))`
  font-size: 18px;
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #ababab;
  &:hover {
    background-color: #eaeaea;
  }
`;

export default AWSSettingModal;
