import React, { useContext, useEffect, useState } from "react";
//npm install @material-ui/core 라이브러리 설치
import { Table, TableHead, TableBody, TableRow, TableCell, Menu, MenuItem, Box, IconButton } from "@mui/material";

import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";

import AWSSettingTable from "./AWS_Setting_Table";
import AWSSettingModal, { DeleteModal } from "./AWS_Setting_Modal";
import classes from "./AWS_Setting.module.css";
import Layout from "components/Layout";

import { socket } from "utils/socket";
import { SnackBarContext } from "components/SnackBar";
import detectMobileDevice from "utils/detectMobileDevice";

const AWS_Setting = (props) => {
  const isMobile = detectMobileDevice(window.navigator.userAgent);
  const { setOpen, setSeverity, setText } = useContext(SnackBarContext);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [moreDorpDown, setMoreDropDown] = useState(null);
  /*모달 창에서 받아온 props값 다루는 useState */
  const [selectedOption, setSelectedOption] = useState(null);
  const [isDeleteModalOpened, setDeleteModalOpened] = useState(false);
  /*dummy 테이블 배열 */
  const [dummy, setDummy] = useState([
    {
      key: 1,
      id: 1,
      server: "dummy1",
      status: "실행중",
      type: "t3.micro",
    },
  ]);

  const [instances, setInstances] = useState([]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  /* 모달 창에서 생성한 sever option 이름 배열에 추가 */
  const addOption = (option) => {
    console.log(option);
    socket.emit("createInstance", option);
    // setSelectedOption(option);
    // const newDummy = [...dummy];
    // const newId = newDummy.length + 1;
    // /* 콘솔 에러 안뜨게 아무값이나 넣음 */
    // const newKey = newDummy.length + 2;
    // newDummy.push({
    //   key: newKey,
    //   id: newId,
    //   sever: option,
    //   status: "실행중",
    //   type: "t3.micro",
    // });
    // setDummy(newDummy);
    closeModal();
  };

  // 드롭다운 메뉴를 표시하기 이벤트 설정
  const handleMoreClick = (event) => {
    setMoreDropDown(event.currentTarget);
  };

  // id값을 이용하여 배열에 할당된 id 토대로 삭제
  const handleRemoveClick = (e) => {
    setDeleteModalOpened(true);
    // if (selectedOption !== null) {
    //   const newDummy = dummy.filter((item) => item.id !== selectedOption);
    //   setDummy(newDummy);
    //   setSelectedOption(null);
    // }
    // socket.emit("deleteInstance", { stackName: selectedOption });
    // setSelectedOption(null);
    setMoreDropDown(null);
  };

  const checkRootStack = (stackName) => {
    return (
      stackName === "Live-Polling-dev" ||
      stackName === "Challenge24-AR-dev" ||
      stackName === "Great-Win-dev" ||
      stackName === "Great-Deal-dev" ||
      stackName === "Great-Pick-dev" ||
      stackName === "Governance6-dev" ||
      stackName === "sbmserver-dev" ||
      stackName === "sbmmobile-dev"
    );
  };

  const refreshInstance = () => {
    setInstances([]);
    socket.emit("getInstances");
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setInstances([]);
      socket.emit("getInstances");
    }, 1000 * 30);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    socket.emit("getInstances");
  }, []);

  useEffect(() => {
    socket.on("getInstances", ({ Stacks }) => {
      let newInstances = [];

      for (var stack of Stacks) {
        const isRootStack = checkRootStack(stack.StackName);

        if (!isRootStack) continue;

        newInstances.push({
          key: stack.StackId,
          id: stack.StackId,
          server: stack.StackName,
          status: stack.StackStatus,
          type: stack.Parameters[0].ParameterValue,
        });
      }

      setInstances(newInstances);
    });

    socket.on("createInstance", ({ success }) => {
      if (success) {
        setOpen(true);
        setSeverity("success");
        setText("서버 생성 요청이 완료되었습니다.");
        socket.emit("getInstances");
      } else {
        alert("서버 생성 요청에 실패했습니다. 다시 시도해주세요.");
        window.location.reload();
      }
    });

    socket.on("deleteInstance", ({ success, ...data }) => {
      if (success) {
        setTimeout(() => {
          setInstances([]);
          setSelectedOption(null);
          setDeleteModalOpened(false);
          socket.emit("getInstances");
          setSeverity("success");
          setText("서버 종료 요청이 완료되었습니다.");
          setOpen(true);
        }, 3000);
      } else {
        alert("서버 종료 요청에 실패했습니다. 다시 시도해주세요.");
        window.location.reload();
      }
    });

    return () => {
      socket.off("getInstances");
      socket.off("createInstance");
      socket.off("deleteInstance");
    };
  });

  return (
    // <Layout>
    <div style={{ padding: "10px", boxSizing: "border-box" }}>
      <div className={classes.titleBox}>
        <h1 style={{ fontSize: "clamp(22px, 7.207vw, 32px)" }}> AWS 서버 관리 </h1>

        <Box sx={{ display: "flex", gap: "20px" }}>
          <button className={classes.button} id={classes.refresh} onClick={refreshInstance}>
            <RefreshOutlinedIcon />
          </button>
          <button className={classes.button} onClick={openModal}>
            +서버 만들기
          </button>
        </Box>
      </div>
      {isModalOpen && <AWSSettingModal onSelect={addOption} onClose={closeModal} />}
      <Table className={isMobile ? classes["aws_table_row"] : null}>
        <TableHead>
          {isMobile ? (
            <TableRow style={{ backgroundColor: "#dee2e6" }}>
              <TableCell width={100}>서버</TableCell>
              <TableCell width={120} sx={{ wordBreak: "keep-all" }}>
                서버 상태
              </TableCell>
              <TableCell width={80} sx={{ wordBreak: "keep-all" }}>
                서버 타입 및 용량
              </TableCell>
              {/* <TableCell sx={{ textAlign: "center", width: { xs: "30px", sm: "100px" } }}>더보기(Actions)</TableCell> */}
              <TableCell sx={{ p: 0 }} />
            </TableRow>
          ) : (
            <TableRow style={{ backgroundColor: "#dee2e6" }}>
              <TableCell>서버(Sever)</TableCell>
              <TableCell>서버 상태(Status)</TableCell>
              <TableCell>서버 타입 및 용량(Type)</TableCell>
              {/* <TableCell sx={{ textAlign: "center", width: { xs: "30px", sm: "100px" } }}>더보기(Actions)</TableCell> */}
              <TableCell sx={{ p: 0 }} />
            </TableRow>
          )}
        </TableHead>
        <TableBody>
          {instances.map((data) => {
            return (
              <AWSSettingTable
                key={data.key}
                id={data.id}
                server={data.server}
                status={data.status}
                type={data.type}
                more={data.more}
                handleMoreClick={handleMoreClick}
                setSelectedOption={setSelectedOption}
              />
            );
          })}
        </TableBody>
      </Table>
      <Menu anchorEl={moreDorpDown} open={Boolean(moreDorpDown)} onClose={() => setMoreDropDown(null)}>
        <MenuItem onClick={handleRemoveClick}>삭제하기</MenuItem>
      </Menu>
      {isDeleteModalOpened && <DeleteModal open={isDeleteModalOpened} onClose={setDeleteModalOpened} selectedOption={selectedOption} />}
    </div>
    // </Layout>
  );
};

const AwsSettingPage = () => {
  return <Layout children={AWS_Setting()} />;
};

export default AwsSettingPage;
