import { React, useState, useEffect} from 'react';

import SubsItem from './Subscribe_item';

import {
    Box, Grid, Chip, IconButton, Typography
} from '@mui/material'

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Kanban = (props) => {
    const {title, count, items} = props
    const [open, setOpen] = useState(true);
    const [icon, setIcon] = useState((<ExpandLessIcon/>));
    const [listStyle, setListStyle] = useState({display:"block"});

    const handleOpen = () => {
        setOpen(!open);
    }

    useEffect(() => {
        if(open){
            setIcon((<ExpandLessIcon/>))
            setListStyle({display:"block"})
        }
        else{
            setIcon((<ExpandMoreIcon/>))
            setListStyle({display:"none"})
        }
    }, [open]);

    return(
        <Grid item xs={12} sm={3.9} sx={{px:1}}>
            <Box alignItems="center" sx={{display:"flex", py:1}}>
                <Typography sx={{width:"100%"}}>{title}</Typography>
                <Grid container alignItems="center" justifyContent="flex-end" sx={{pr:1}}>
                    <Grid item>
                        <Chip size="small" label={count}/>
                    </Grid>
                    <Grid item>
                        <IconButton onClick={handleOpen}>
                            {icon}
                        </IconButton>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{borderRadius:"10px", backgroundColor:"#E5E7EB",py:1}}>
                <Box sx={listStyle}>
                    {items?.map((item, idx) => (
                        <SubsItem title={title} item={item} key={idx}/>
                    ))}
                </Box>
            </Box>
        </Grid>
    )
}

export default Kanban