import React, { useEffect } from "react";
// import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import useInterval from "utils/useInterval";

const Auth = (props) => {
  const { Component, isAuthRequired } = props;

  const AuthenticationCheck = () => {
    const navigate = useNavigate();

    const cookies_str = document.cookie.split(";");
    const cookies = {};
    cookies_str.forEach((cookie) => {
      const [key, value] = cookie.split("=");
      cookies[key.trim()] = value;
    });

    useInterval(() => {
      const cookies_str = document.cookie.split(";");
      const cookies = {};
      cookies_str.forEach((cookie) => {
        const [key, value] = cookie.split("=");
        cookies[key.trim()] = value;
      });

      if (!cookies.auth) {
        if (isAuthRequired) {
          navigate("/login", { replace: true });
        }
      } else {
        if (!isAuthRequired) {
          navigate("/", { replace: true });
        }
      }
    }, 1000 * 10);

    useEffect(() => {
      if (!cookies.auth) {
        if (isAuthRequired) {
          navigate("/login", { replace: true });
        }
      } else {
        if (!isAuthRequired) {
          navigate("/", { replace: true });
        }
      }
      // eslint-disable-next-line
    }, []);

    return <Component />;
  };

  return <AuthenticationCheck />;
};

export default Auth;
