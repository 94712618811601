import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { Box, Button, ListItem } from "@mui/material";

const NavItem = (props) => {
  const { href, icon, title, sidebar } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (href === "/dashboard") setActive(location.pathname === "/" || location.pathname === "/dashboard");
    else setActive(location.pathname.includes(href.slice(0, -1)));
  }, []);

  return (
    <ListItem
      disableGutters
      sx={{
        display: "flex",
        mb: 0.5,
        py: 0,
        px: 2,
      }}
    >
      <Button
        sx={{
          backgroundColor: active && "rgba(255,255,255, 0.08)",
          color: active ? "rgb(10, 185, 81)" : "rgb(209, 213, 219)",
          borderRadius: 1,
          px: 3,
          py: 1,
          width: "100%",
          justifyContent: "flex-start",
          textAlign: "left",
          "&:hover": {
            backgroundColor: "rgba(255,255,255, 0.08)",
          },
          fontSize: "13px",
        }}
        startIcon={icon}
        onClick={() => {
          sidebar();
          navigate(href);
        }}
      >
        <Box sx={{ flexGrow: 1 }}>{title}</Box>
      </Button>
    </ListItem>
  );
};

export default NavItem;
