import React from 'react'

import {Tab, Tabs, Badge, Divider} from '@mui/material'

export const CustomerTab = (props) => {
    const { index, handleTab } = props
    return(
        <Tabs value={index} onChange={handleTab} textColor="secondary" indicatorColor="secondary">
            <Tab label="All" id={0}/>
            <Tab label="Super Admin" id={1}/>
            <Tab label="Admin" id={2}/>
        </Tabs>
    )
}

export const CustomerDetailTab = (props) => {
    const { detailTabIndex, handleTabIndex, badgeCount, isAccepted } = props
    return (
        <>
            <Tabs value={detailTabIndex} onChange={handleTabIndex} textColor="secondary" indicatorColor="secondary">
                <Tab label="Details" id={0}/>
                <Tab 
                label={<Badge color="error" badgeContent={badgeCount}>Subscribes</Badge>} id={1} sx={{display:isAccepted?"":"none"}}/>
                <Tab label="Logs" id={2} sx={{display:isAccepted?"":"none"}}/>
            </Tabs>
            <Divider sx={{mb:"12px"}}/>
        </>
        
    )
}