import React from 'react';

import { 
    Box, ListItem, Divider, Typography 
} from '@mui/material';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const Message = (props) => {
    const {title, message, date, handleMessage, id} = props;

    return(
        <Box>
            <ListItem sx={{display:'flex',}}>
                <Box sx={{width:"100%", mx:1}}>
                    <Typography sx={{mb:2}}> {title}</Typography>
                    <Typography sx={{fontSize:"12px"}}> {message}</Typography>
                    <Typography sx={{fontSize:"11px"}}> {date}</Typography>
                </Box>
                <Box >
                    <IconButton size="small" color="success" onClick={handleMessage} id={id}>
                        <CloseIcon sx={{fontSize:"16px"}}/>
                    </IconButton>
                </Box>
            </ListItem>
            <Divider sx ={{borderColor: 'rgba(45, 55, 72, 0.15)',}}/>
        </Box>
    )
}

export default Message;