import React, { useState, useEffect } from 'react';

import ConfirmDialog from './dialog';
import { socket } from '../utils/socket';

import { 
    Box, IconButton, Divider, Grid, Button, Typography, Modal, TextField, InputAdornment
} from '@mui/material';

import  {MobileDatePicker}  from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CloseIcon from '@mui/icons-material/Close';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  borderRadius: '16px',
  boxShadow: 24,
  p: 1,
};

export default function BasicModal(props) {
    const {open, handleClose, item, title} = props
    const [confirm, setConfirm] = useState(false);
    const [confirmTitle, setConfirmTitle] = useState("");
    const [confirmContent, setConfirmContent] = useState("");
    const [modifyModalOpen, setModifyModalOpen] = useState(false);
    const [extendModalOpen, setExtendModalOpen] = useState(false);
    
    const handleConfirm = (e) => {
        var value = (e.target.value === "true")
        setConfirm(false)
        
        if(value){
            socket.emit("deleteOwnGame", {_id:item._id})
        }
    }

    const handleDelete = () => {
        setConfirm(true);
        setConfirmTitle("구독 게임 권한을 삭제하시겠습니까?")
        setConfirmContent("삭제한 이후 다시 되돌릴 수 없습니다")
    }
    const handleModify = () => {
        setModifyModalOpen(!modifyModalOpen)
    }
    const handleExtend = () => {
        setExtendModalOpen(!extendModalOpen)
    }

    const Url = (props) => {
        const { item } = props
        return (
            <>
                {
                    Object.entries(item.url).map((i, idx) => (
                        <Typography key={idx} sx={{ my: 1 }}>
                            {i[0]} : <a href={i[1]} target="_blank" rel="noopener noreferrer">{i[1]}</a>
                        </Typography>
                    ))
                }
            </>
        )
    }

    return (
        <div>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Box sx={{display:"flex", justifyContent:"flex-end"}}>
                    <IconButton onClick={handleClose}>
                        <CloseIcon/>
                    </IconButton>
                </Box>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    {item?.game.name}
                </Typography>
                <Divider sx={{my:1, borderColor: '#2D3748'}}/>
                <Box sx={{ p:1, display:"flex"}}>
                    <Grid container>
                        <Grid item xs={12} md={7.5} sx={{mx:1}}>
                            <Typography id="modal-modal-description" sx={{ my: 1 }}>
                                URL
                            </Typography>
                            <Box sx={{border:1, borderRadius:"10px", width:'100%', backgroundColor:"#E5E7EB"}}>
                                {
                                    title === "구독 승인 전"?
                                        (<Typography>구독 승인 이후 URL이 제공됩니다.</Typography>):
                                            title === "구독 만료"?
                                                (<Typography>구독 기간 및 인원이 만료되어 URL 제공이 종료됩니다.</Typography>):
                                                (<Url item={item.game}/>)
                                }
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={3.5} sx={{ml:1}}>
                            <Typography>만료날짜</Typography>
                            <Box sx={{border:1, borderRadius:"10px", width:'100%', mb:1, backgroundColor:"#E5E7EB"}}>
                                <Typography sx={{display:"flex", justifyContent:"flex-end", pr:1}}>{item?.expireDate.slice(0,10)}</Typography>
                            </Box>
                            <Typography>접속 가능 인원</Typography>
                            <Box sx={{border:1, borderRadius:"10px", width:'100%', mb:1, backgroundColor:"#E5E7EB"}}>
                                <Typography sx={{display:"flex", justifyContent:"flex-end", pr:1}}>{item?.people} (명)</Typography>
                            </Box>
                            <Box sx={{display:"flex", justifyContent:"center"}}>
                                <Button variant="contained" onClick={handleExtend} color="success" sx={{display:(title==="구독중"||title==="구독 만료")?"flex":"none", justifyContent:"center", width:'70%', mx:1}}>구독 연장 신청</Button>
                                <Button variant="contained" onClick={handleModify} sx={{display:(title==="구독 승인 전")?"flex":"none", justifyContent:"center", width:'70%', mx:1}}>구독 신청 수정</Button>
                                <Button variant="contained" onClick={handleDelete} color="error" sx={{display:(title==="구독 승인 전")?"flex":"none", justifyContent:"center", width:'70%', mx:1}}>구독 신청 삭제</Button>
                                <Button variant="contained" onClick={handleDelete} color="error" sx={{display:(title==="구독중"||title==="구독 만료")?"flex":"none", justifyContent:"center", width:'70%', mx:1}}>삭제</Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Modal>
        <ConfirmDialog open={confirm} handleClose={handleConfirm} title={confirmTitle} content={confirmContent}/>
        <ModifyModal 
            open={modifyModalOpen} onClose={handleModify} expireDate={item?.expireDate} people={item?.people} subscribeId={item._id} gameId={item.game._id}/>
        <ExtendModal
            open={extendModalOpen} onClose={handleExtend} expireDate={item?.expireDate} people={item?.people} subscribeId={item._id} gameId={item.game._id}/>
        </div>
    );
}

export const ModifyModal = (props) => {
    const { open, onClose, subscribeId } = props

    const [calendarOpen, setCalendarOpen] = useState(false);
    const [expireDate, setExpireDate] = useState(props.expireDate);
    const [people, setPeople] = useState(props.people);

    const handleCalendar = () => {
        setCalendarOpen(!calendarOpen)
    }
    const handlePeople = (e) => {
        setPeople(e.target.value);
    }
    const submitModifiedData = () => {
        if(window.confirm("구독 신청 정보를 수정하시겠습니까?")){
            socket.emit("modifySubScribe", {subscribeId: subscribeId, expireDate: expireDate, people: people})
        }
    }

    useEffect(() => {
        setExpireDate(props.expireDate)
        setPeople(props.people)
        // eslint-disable-next-line
    }, [open]);

    return(
        <Modal open={open} onClose={onClose}>
            <Box sx={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                <Box sx={{...style, width:"400px", height:"400px"}}>
                    <Box sx={{display:"flex", justifyContent:"flex-end"}}>
                        <IconButton onClick={onClose}>
                            <CloseIcon/>
                        </IconButton>
                    </Box>
                    <Box sx={{display:"flex", justifyContent:"center"}}>
                        <Typography sx={{fontSize:"1.0909rem"}}>구독 신청 수정</Typography>
                    </Box>
                    <Box sx={{height:"200px", backgroundColor:"lightgray", borderRadius:"10px", p:2, m:2, display:"grid", alignItems:"center"}}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <MobileDatePicker
                                label="만료 날짜"
                                open={calendarOpen}
                                onOpen={handleCalendar}
                                onClose={handleCalendar}
                                inputFormat="yyyy/MM/dd"
                                value={expireDate}
                                onChange={(newExpireDate) => {
                                    setExpireDate(newExpireDate)
                                }}
                                renderInput={(params) => <TextField {...params} size="small" sx={{width:'100%', my:1}}
                                                            InputProps={{
                                                                endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton onClick={handleCalendar} edge="end">
                                                                    <CalendarMonthIcon />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                                ),
                                                            }}/>}
                            />
                        </LocalizationProvider>
                        <TextField fullWidth size="small" type="number" label="접속 가능 인원 (명)" sx={{my:1}} onChange={handlePeople} value={people}></TextField>
                    </Box>
                    <Box sx={{py:1}}>
                        <Grid container spacing={3} sx={{px:2}}>
                            <Grid item xs={6}>
                                <Button variant="contained" fullWidth onClick={submitModifiedData}>수정</Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button variant="contained" fullWidth color="error" onClick={onClose}>닫기</Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}

const ExtendModal = (props) => {
    const { open, onClose, subscribeId } = props

    const [calendarOpen, setCalendarOpen] = useState(false);
    const [expireDate, setExpireDate] = useState(props.expireDate);
    const [people, setPeople] = useState(props.people);

    const handleCalendar = () => {
        setCalendarOpen(!calendarOpen)
    }
    const handlePeople = (e) => {
        setPeople(e.target.value);
    }
    const submitExtendedData = () => {
        if(window.confirm("구독 연장 신청하시겠습니까?")){
            socket.emit("extendSubScribe", {subscribeId: subscribeId, expireDate: expireDate, people: people})
        }
    }

    useEffect(() => {
        setExpireDate(props.expireDate)
        setPeople(props.people)
        // eslint-disable-next-line
    }, [open]);

    return(
        <Modal open={open} onClose={onClose}>
            <Box sx={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                <Box sx={{...style, width:"400px", height:"400px"}}>
                    <Box sx={{display:"flex", justifyContent:"flex-end"}}>
                        <IconButton onClick={onClose}>
                            <CloseIcon/>
                        </IconButton>
                    </Box>
                    <Box sx={{display:"flex", justifyContent:"center"}}>
                        <Typography sx={{fontSize:"1.0909rem"}}>구독 연장 신청</Typography>
                    </Box>
                    <Box sx={{height:"200px", backgroundColor:"lightgray", borderRadius:"10px", p:2, m:2, display:"grid", alignItems:"center"}}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <MobileDatePicker
                                label="만료 날짜"
                                open={calendarOpen}
                                onOpen={handleCalendar}
                                onClose={handleCalendar}
                                inputFormat="yyyy/MM/dd"
                                value={expireDate}
                                onChange={(newExpireDate) => {
                                    setExpireDate(newExpireDate)
                                }}
                                renderInput={(params) => <TextField {...params} size="small" sx={{width:'100%', my:1}}
                                                            InputProps={{
                                                                endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton onClick={handleCalendar} edge="end">
                                                                    <CalendarMonthIcon />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                                ),
                                                            }}/>}
                            />
                        </LocalizationProvider>
                        <TextField fullWidth size="small" type="number" label="접속 가능 인원 (명)" sx={{my:1}} onChange={handlePeople} value={people}></TextField>
                    </Box>
                    <Box sx={{py:1}}>
                        <Grid container spacing={3} sx={{px:2}}>
                            <Grid item xs={6}>
                                <Button variant="contained" fullWidth color="success" onClick={submitExtendedData}>연장 신청</Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button variant="contained" fullWidth color="error" onClick={onClose}>닫기</Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}