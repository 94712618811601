import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useMediaQuery } from "react-responsive";

import Message from "./Message";
import { socket } from "../utils/socket";

import { Box, Grid, Avatar, AppBar, Toolbar, Typography, IconButton, Badge, Menu, MenuItem, Divider, Tooltip } from "@mui/material";

import NotificationsIcon from "@mui/icons-material/Notifications";
import LogoutIcon from "@mui/icons-material/Logout";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import MenuIcon from "@mui/icons-material/Menu";

const Header = (props) => {
  let { menu, handleSideBar, name, status } = props;
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width: 900px)" });

  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies();

  const [anchorEl, setAnchorEl] = useState(null);
  const [notifications, setNotifications] = useState(null);
  const [notiNumber, setNotiNumber] = useState(1);
  const [messages, setMessages] = useState([
    {
      title: "시스템 준비중...",
      message: "시스템 준비중입니다.",
      date: "2022-08-12 15:13",
    },
  ]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleNotifications = (e) => {
    if (!notiNumber) return;
    setNotifications(e.currentTarget);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
    setNotifications(null);
  };

  const handleNotiNumber = () => {
    setNotiNumber(0);
    handleClickAway(null);
    setMessages([]);
  };

  const handleMessage = (e) => {
    const index = e.currentTarget.id;
    let newArray = messages;
    newArray.splice(index, 1);
    setMessages(newArray);
    setNotiNumber(notiNumber - 1);
  };

  const logout = () => {
    removeCookie("auth", { path: "/" });
    alert("로그아웃 되었습니다.");
    navigate("/login");
  };

  useEffect(() => {
    if (!notiNumber) handleClickAway();
  }, [notiNumber]);

  useEffect(() => {
    socket.on("message", (data) => {
      console.log(data);
    });
  }, []);

  return (
    <AppBar sx={{ borderRadius: "16px", width: "100%", position: "relative" }}>
      <Toolbar sx={{ px: 2, borderRadius: "16px", display: "flex", justifyContent: "space-between" }} style={{ background: "#FFFFFF" }}>
        {/* <IconButton sx={menu ? {} : { display: "none" }} onClick={handleSideBar}> */}
        <IconButton sx={{ opacity: menu ? 1 : 0 }} onClick={handleSideBar} disabled={!menu}>
          <MenuIcon />
        </IconButton>

        <Box sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
          <Typography sx={{ font: "700 24px Pretendard", fontSize: "clamp(17px, 5.405vw, 24px)", color: "#000" }}>
            위드플러스 솔루션
          </Typography>
        </Box>

        <Grid container justifyContent="flex-end" alignItems="center" sx={{ width: "auto" }}>
          <Grid item>
            <Tooltip title="알림">
              <IconButton onClick={handleNotifications}>
                <Badge badgeContent={notiNumber} color="error">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
            </Tooltip>
            <Menu
              MenuListProps={{ disablePadding: true }}
              anchorEl={notifications}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              open={Boolean(notifications)}
              onClose={handleClickAway}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <Box sx={{ bgcolor: "blue", p: 2, display: "flex", minWidth: "100px" }}>
                <Typography sx={{ color: "white", fontSize: "20px", width: "-webkit-fill-available" }}> 알림</Typography>
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <Tooltip title="읽음 처리">
                      <IconButton size="small" onClick={handleNotiNumber}>
                        <MarkEmailReadIcon sx={{ color: "white" }} />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Box>
              {messages.map((message) => (
                <Message
                  key={message.title}
                  title={message.title}
                  message={message.message}
                  date={message.date}
                  handleMessage={handleMessage}
                  id={messages.indexOf(message)}
                />
              ))}
            </Menu>
          </Grid>
          <Grid item>
            <Tooltip title="설정">
              <Avatar
                sx={{
                  width: 40,
                  height: 40,
                  ml: 1,
                }}
                onClick={handleMenu}
              />
            </Tooltip>
            <Menu
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClickAway}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <Box sx={{ px: 1, py: 2, display: "flex" }}>
                <Avatar
                  sx={{
                    width: 40,
                    height: 40,
                    ml: 1,
                    mr: 2,
                  }}
                />
                <Box>
                  <Typography variant="h6" sx={{ mr: 10 }}>
                    {name}
                  </Typography>
                  <Typography variant="body2" color="neutral.400">
                    {status}
                  </Typography>
                </Box>
              </Box>
              <Divider />
              <Box sx={{ px: 1, py: 2 }}>
                <MenuItem>
                  <AccountCircleIcon sx={{ pr: 2 }} />
                  Profile
                </MenuItem>
                <MenuItem> My account</MenuItem>
                <MenuItem onClick={logout}>
                  <LogoutIcon sx={{ pr: 2 }} /> Logout
                </MenuItem>
              </Box>
            </Menu>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
